import React, { lazy, Suspense } from 'react';

import urlMap from 'url-map';
import { createPage, InitPageConfiguration } from 'services/init-page';

const GuestPage = lazy(() => import(/* webpackChunkName: "GuestPage" */ './GuestPage'));
const Error404Page = lazy(() =>
    import(/* webpackChunkName: "Error404Page" */ 'widgets/error-404-page').then((module) => ({
        default: module.Error404Page,
    })),
);

const configuration: InitPageConfiguration = {
    mountPoint: () => document.getElementById('guest-login-page'),
    urlMap,
};

const page = createPage<{ mode: string } | undefined>('GuestLoginPage', configuration, (initialSettings) => {
    if (initialSettings.data?.mode === 'error404') {
        return (
            <Suspense fallback={<div />}>
                <Error404Page />
            </Suspense>
        );
    }
    return (
        <Suspense fallback={<div />}>
            <GuestPage />
        </Suspense>
    );
});

page.saveAndRenderIfNeeded();
