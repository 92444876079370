import React, { useMemo } from 'react';

import { WtPopup3Props } from 'components/wt-popup3';
import { ActionButtonItemType } from 'components/wt-popup3/footer';
import { Z_INDEX_COOKIE_POPUP } from 'components/wt-popup3/constants';

import { CookiePopupUIProps } from '../types/CookiePrivacySettingsPopupUI.types';
import * as Styled from '../styled/CookiePrivacySettingsPopupUI.styled';

export const CookiePrivacySettingsPopupUI = (props: CookiePopupUIProps) => {
    const { common, acceptAll, reject } = props;

    const { title: titleCommon, content: contentCommon, btnTexts: commonBtnText } = common;
    const { acceptAll: acceptBtnTextCommon, reject: rejectBtnTextCommon } = commonBtnText;

    const actionButtons = useMemo((): WtPopup3Props['actionButtons'] => {
        const acceptBtnData: ActionButtonItemType = {
            content: acceptBtnTextCommon,
            type: 'alternative',
            key: 'accept',
            onClick: () => {
                acceptAll?.();
            },
        };
        return {
            direction: 'vertical',
            align: 'right',
            items: [
                acceptBtnData,
                {
                    content: rejectBtnTextCommon,
                    key: 'reject',
                    kind: 'secondary',
                    onClick: () => {
                        reject?.();
                    },
                },
            ],
        };
    }, [acceptAll, acceptBtnTextCommon, reject, rejectBtnTextCommon]);

    const titlePopup = useMemo(() => titleCommon, [titleCommon]);

    const contentPopup = useMemo(
        () => (typeof contentCommon === 'string' ? <span>{contentCommon}</span> : contentCommon),
        [contentCommon],
    );

    return (
        <Styled.Popup
            isBottomLeftPosition
            title={titlePopup}
            zIndex={Z_INDEX_COOKIE_POPUP}
            width={413}
            actionButtons={actionButtons}
        >
            {contentPopup}
        </Styled.Popup>
    );
};
