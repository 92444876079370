import { loadRemote } from '@module-federation/runtime';

import { Lcid, TypeTxGlobalAuth } from 'types';
import notify from 'services/notify';
import { Dic } from 'services/dictionary';
import { sentryClient, SentryError } from 'services/sentry';

import { resolveTxGA } from './provide-tx-ga';
import { initOpts } from './constants';

const handleError = () => {
    notify.error(Dic.word('wt_all__notification__common_error'));
    sentryClient.captureException(new SentryError('Не удалось обратиться к GlobalAuth', 'GlobalAuthNotFound'));
    window.__txGlobalAuthProvider?.txGlobalAuthLoadingDefer?.reject();
};

export function initScript(lang: Lcid) {
    if (window.TxGlobalAuth) {
        resolveTxGA(window.TxGlobalAuth);
    }

    const { env } = initOpts;
    // init({
    //     name: 'limex-mf',
    //     remotes: [
    //         {
    //             name: 'txAuthWidget',
    //             entry: src,
    //         },
    //     ],
    // });
    loadRemote<{ TxGlobalAuth: TypeTxGlobalAuth }>('txAuthWidget').then((module) => {
        if (!module) {
            handleError();
            return;
        }
        const { TxGlobalAuth } = module;
        TxGlobalAuth.init({
            appName: 'limex',
            txAuth: {
                spc: {
                    registration: { txUserFlowSource: 0 },
                },
            },
            appVersion: '1.0.0',
            // provider: ['LIMEX_DEV_KRATOS_TOKEN', GUEST_PROVIDER],
            lang,
            env,
            parseUrl: true,
        }).then(() => {
            resolveTxGA(TxGlobalAuth);
        });
    });
}

// import { Lcid } from 'types';
//
// import { resolveTxGA } from './provide-tx-ga';
// import { initOpts } from './constants';
//
// console.log(process.env);
// export async function initScript(lang: Lcid) {
//     if (window.TxGlobalAuth) {
//         resolveTxGA(window.TxGlobalAuth);
//     }
//     // if (!process.env.IS_STORYBOOK_BUILD) {
//     //     // @ts-ignore
//     //     await import('txAuthWidget');
//     // }
//     const { env } = initOpts;
//
//     const txGlobalAuth = window.TxGlobalAuth;
//     if (txGlobalAuth) {
//         txGlobalAuth
//             .init({
//                 variant: 'spc',
//                 appName: 'limex',
//                 txAuth: {
//                     spc: {
//                         registration: { txUserFlowSource: 0 },
//                     },
//                 },
//                 appVersion: '1.0.0',
//                 // provider: ['LIMEX_DEV_KRATOS_TOKEN', GUEST_PROVIDER],
//                 lang,
//                 env,
//                 parseUrl: true,
//             })
//             .then(() => {
//                 resolveTxGA(txGlobalAuth);
//             });
//     }
// }
