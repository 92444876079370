import { isOptional, parseParameter } from './utils';
import type { LinksFunctions, MatchedRoutes } from './types';
import { routeRegexes } from './generated/regexes';

/**
 * Пытается сопоставить URL с одним из маршрутов, определенных в приложении.
 * Возвращает имя маршрута и разобранные параметры, если сопоставление успешно, или null, если сопоставление не удалось.
 */
export function matchUrl(url: string) {
    // Создаем объект URL для парсинга входного URL.
    const parsedUrl = new URL(url, 'https://domain.com');

    // Получаем список всех маршрутов и их путей.
    const entries = Object.entries(routeRegexes);

    // Проходим по каждому маршруту и пытаемся найти совпадение.
    for (let i = 0; i < entries.length; i++) {
        const [routeName, routePath] = entries[i];

        // Преобразуем маршрут в формат path-to-regexp и пытаемся сопоставить его с путем из URL.
        const match = routePath.regexp.exec(parsedUrl.pathname);
        if (match) {
            const output: Record<string, string | number | boolean> = {};
            const params =
                routePath.keys?.reduce((acc, key, index) => {
                    acc[key] = match[index + 1];
                    return acc;
                }, {} as Record<string, string>) ?? {};
            const searchParams = new URLSearchParams(parsedUrl.searchParams);

            // Получаем валидаторы для параметров текущего маршрута из предварительно сгенерированного файла.
            const paramValidatorsEntries = Object.entries(routePath.validators ?? {});

            let validatedAll = true;

            // Проверяем каждый параметр маршрута на соответствие валидаторам.
            for (let j = 0; j < paramValidatorsEntries.length; j++) {
                const [paramName, paramValidators] = paramValidatorsEntries[j];

                // Извлекаем значение параметра из URL или из строки запроса (query string).
                let paramStringValue = (params[paramName] as string) ?? searchParams.get(paramName);

                // Если параметр является хешем, извлекаем его из хеш-части URL.
                if (paramName === 'hash') {
                    paramStringValue = parsedUrl.hash;
                }

                // Если параметр не найден и он опционален, пропускаем его, иначе считаем валидацию неуспешной.
                if (paramStringValue === undefined || paramStringValue === null) {
                    if (isOptional(paramValidators)) {
                        // Параметр опционален, продолжаем без валидации.
                        // eslint-disable-next-line no-continue
                        continue;
                    } else {
                        // Параметр обязателен, но не найден - валидация не прошла.
                        validatedAll = false;
                        break;
                    }
                }

                // Парсим значение параметра с учетом валидаторов.
                const parsedParameterValue = parseParameter(paramStringValue, paramValidators);

                // Если значение не удалось распарсить, валидация не прошла.
                if (parsedParameterValue === undefined) {
                    validatedAll = false;
                    break;
                }

                // Сохраняем успешно распарсенное значение в результирующем объекте.
                output[paramName] = parsedParameterValue;
            }

            // Если все параметры успешно валидированы, возвращаем маршрут и параметры.
            if (validatedAll) {
                return { route: routeName, params: output } as MatchedRoutes<LinksFunctions>;
            }
        }
    }

    // Если ни один маршрут не совпал, возвращаем null.
    return null;
}
