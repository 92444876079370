export enum SizeWtPopup3Enum {
    TINY = 'tiny',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}
export const Z_INDEX_OVERLAY = 1000;
export const Z_INDEX_DIALOG = 1001;
export const Z_INDEX_COOKIE_POPUP = 1400;

export const DEFAULT_SIZE_WT_POPUP_3 = SizeWtPopup3Enum.MEDIUM;
