import { format } from 'date-fns';

import { DefaultPerson } from './person';

export const defaultPerson: DefaultPerson = {
    id: 0,
    type: 1,
    lcid: 'en',
    domainLcid: 'en',
    displayName: '',
    countryCode: 'US',
    countryName: 'USA',
    counters: {
        followers: 0,
        following: 0,
        globalInfluence: 0,
    },
    created: format(new Date(), 'yyyy-MM-dd HH:mm:ss.SSSxxxx'),
    channel: {
        user: '',
    },
    hasAvatar: false,
    hasEmail: false,
    image: 'https://static.whotrades.com/images/blank_male_med.png',
    images: {},
    isMale: true,
    isVerified: false,
    isVerifiedAuthor: false,
    isDemoAccountNotification: false,
    cover: null,
    description: null,
    firstName: '',
    inviteLink: null,
    isActive: false,
    lastName: '',
    lastVisit: null,
    occupation: null,
    shareUrl: '',
    socialNets: {},
    personalAlias: null,
    url: '',
    objectPermissions: [],
    data: {},
    popupShown: {
        isCopytradingLandingShown: false,
        isDemoExpiredPopUpShown: false,
        isLimeAccountCongratulationShown: false,
        isLimeAccountConnectionFailShown: false,
    },
    isBlockedByCurrentUser: false,
    isBlockedByGivenUser: false,
};
