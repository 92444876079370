import { ILoginResponse, TypeTxGlobalAuth } from 'types';
import Deferred from 'services/utils/deferred';
import { taskQueue, createTask } from 'services/task-queue';

export function provideTxGA(): Deferred<TypeTxGlobalAuth>['promise'] {
    if (!window.__txGlobalAuthProvider) {
        window.__txGlobalAuthProvider = {};
    }
    const provider = window.__txGlobalAuthProvider;
    if (!provider.txGlobalAuthLoadingDefer) {
        provider.txGlobalAuthLoadingDefer = new Deferred<TypeTxGlobalAuth>();
    }
    return provider.txGlobalAuthLoadingDefer.promise;
}

export const resolveTxGA = (txGlobalAuth: TypeTxGlobalAuth) => {
    window.__txGlobalAuthProvider?.txGlobalAuthLoadingDefer?.resolve(txGlobalAuth);
};

type JWTInfo = Partial<ILoginResponse>;

export function provideJWT(): Deferred<JWTInfo>['promise'] {
    if (!window.__txGlobalAuthProvider) {
        window.__txGlobalAuthProvider = {};
    }
    const provider = window.__txGlobalAuthProvider;
    if (!provider.txGlobalAuthJWTDefer) {
        provider.txGlobalAuthJWTDefer = new Deferred<JWTInfo>();
    }
    return provider.txGlobalAuthJWTDefer.promise;
}

export const resolveJWT = (jwtObj: Partial<ILoginResponse>) => {
    provideJWT();
    const provider = window.__txGlobalAuthProvider;
    if (provider) {
        provider.txGlobalAuthJWTDefer?.resolve(jwtObj);
        provider.txGlobalAuthJWTDefer = new Deferred<JWTInfo>();
        provider.txGlobalAuthJWTDefer.resolve(jwtObj);
    }
};

export const enrichJWT = async () => {
    try {
        const txGlobalAuth = await provideTxGA();
        return new Promise<boolean>((resolve) => {
            const taskLimeAuthorization = createTask({
                name: 'GALimeAuthorization', // это нужно для того, чтобы не сбивала очередь попапов если пользователь заходит, там где есть обогащение
                onStart: async () => {
                    try {
                        const response = await txGlobalAuth.requireLimeAuthorization();
                        resolveJWT(response);
                        resolve(true);
                    } catch {
                        resolve(false);
                    } finally {
                        taskLimeAuthorization.finish();
                    }
                },
            });
            taskQueue.addTaskAndRunASAP({ task: taskLimeAuthorization });
        });
    } catch {
        return false;
    }
};

export const createDemoAccount = async ({ firstName, lastName }: { firstName: string; lastName: string }) => {
    try {
        const txGlobalAuth = await provideTxGA();
        return await txGlobalAuth.createClient({
            initialData: {
                firstName,
                lastName,
            },
        });
    } catch {
        return null;
    }
};
