import type { TypeValidator } from './types';

/**
 * Проверяет, является ли хотя бы один валидатор в массиве опциональным (например, типом null или undefined).
 */
export function isOptional(typeValidators: TypeValidator[]) {
    return typeValidators.some(({ kind }) => kind === 'null' || kind === 'undefined');
}

/**
 * Преобразует входную строку в примитивное значение на основе предоставленных валидаторов типов.
 */
function parsePrimitive(input: string, typeValidators: TypeValidator[]): string | number | boolean | undefined {
    for (let i = 0; i < typeValidators.length; i++) {
        const descriptor = typeValidators[i];

        if (descriptor.kind === 'number') {
            const isInteger = /^[-+]?\d+$/.test(input);
            const isFloat = /^[-+]?\d*\.\d+$/.test(input);
            if (isInteger) {
                return parseInt(input, 10);
            }
            if (isFloat) {
                return parseFloat(input);
            }
        } else if (descriptor.kind === 'string') {
            return input;
        } else if (descriptor.kind === 'boolean') {
            return input === 'true' || input === '1';
        } else if (descriptor.kind === 'literal') {
            if (input === `${descriptor.literalValue}`) {
                return descriptor.literalValue;
            }
        }
    }
    return undefined;
}

/**
 * Пытается разобрать входной параметр, проверяя его на соответствие примитивным и шаблонным валидаторам типов.
 */
export function parseParameter(input: string, typeValidators: TypeValidator[]): string | number | boolean | undefined {
    const primitive = parsePrimitive(input, typeValidators);
    if (primitive !== undefined) {
        return primitive;
    }
    const templateValidators = typeValidators.filter(({ kind }) => kind === 'template');
    for (let i = 0; i < templateValidators.length; i++) {
        const validator = templateValidators[i];

        if (validator.kind === 'template' && input.startsWith(validator.prefix) && input.endsWith(validator.suffix)) {
            const placeholder = input.slice(validator.prefix.length, input.length - validator.suffix.length);
            const parsedValue = parsePrimitive(placeholder, validator.placeholder);
            if (parsedValue !== undefined) {
                return input;
            }
        }
    }

    return undefined;
}

/**
 * Преобразует маршрут react-router в формат, совместимый с path-to-regexp.
 */
export function reactRouteToPathToRegexpRoute(route: string) {
    return route.replace(/\/:([^/]+)\?/g, '{/:$1}?');
}
