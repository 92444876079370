import posthog from 'posthog-js';

import { isProd } from 'services/utils/env';

const apiHost = process.env.REACT_APP_POSTHOG_API_HOST ?? '';

export function initializePostHog(apiKey: string) {
    posthog.init(apiKey, {
        api_host: apiHost,
        person_profiles: 'always',
    });
    if (!isProd()) console.log('Posthog initialized');
}

export function posthogIdentifyUser(kratosId: string) {
    posthog.identify(kratosId);
    if (!isProd()) console.log('User identified');
}

export function posthogLogout() {
    posthog.reset();
    if (!isProd()) console.log('Posthog logout');
}
