import WtLogger from 'services/utils/wt-logger';

import { createTask as createTaskForQueue } from './createTask';
import { CreateAndAddTaskArgs, CreateTaskArgs, Task, TaskName } from './types';
import { ORDER_TASKS } from './constants';

const taskQLogger = WtLogger.register({
    name: 'taskQ',
});

type ActionsAfterDoneQueueScenario = 'reload';
type ActionsAfterDoneQueueFunc = () => void;
export type ActionsAfterDoneQueueItem = ActionsAfterDoneQueueFunc | ActionsAfterDoneQueueScenario;

type AddTaskAndRunASAPArgs = { task: Task } & Pick<CreateAndAddTaskArgs, 'isRunQueueImmediately'> & {
        actionAfterDoneQueue?: ActionsAfterDoneQueueItem;
    };

/**
 * TaskQueue - Класс для управления и выполнения очереди асинхронных задач.
 * Управляет порядком задач на основе ORDER_TASKS.
 */
class TaskQueue {
    private queue: Task[]; // Хранит текущую очередь задач.

    private currentTask: Task | null;

    private actionsAfterDoneQueue: Array<ActionsAfterDoneQueueItem> | [];

    private isRunning: boolean; // Указывает, выполняется ли очередь в данный момент.

    constructor() {
        this.queue = [];
        this.isRunning = false;
        this.currentTask = null;
        this.actionsAfterDoneQueue = [];
    }

    public createTask(args: CreateTaskArgs) {
        const { name: nameTask } = args;

        const taskFounded = this.findTaskByName(nameTask);

        if (taskFounded) {
            return taskFounded;
        }

        return createTaskForQueue(args);
    }

    public findTaskByName(name: string): Task | undefined {
        if (this.queue?.length < 1) {
            return undefined;
        }

        return this.queue.find((task) => task.name === name);
    }

    public addActionAfterDoneQueue(funcOrScenario: Array<ActionsAfterDoneQueueItem> | ActionsAfterDoneQueueItem) {
        if (!funcOrScenario) {
            return this.actionsAfterDoneQueue;
        }

        if (Array.isArray(funcOrScenario) && funcOrScenario.length > 0) {
            this.actionsAfterDoneQueue = [...this.actionsAfterDoneQueue, ...funcOrScenario];
        }

        if (typeof funcOrScenario === 'function' || typeof funcOrScenario === 'string') {
            this.actionsAfterDoneQueue = [...this.actionsAfterDoneQueue, funcOrScenario];
        }

        return this.actionsAfterDoneQueue;
    }

    private async executeActionsAfterDone(): Promise<void> {
        if (this.actionsAfterDoneQueue.length > 0 && !this.isRunning && this.queue.length < 1) {
            const isReload = Boolean(this.actionsAfterDoneQueue.find((i) => typeof i === 'string' && i === 'reload'));

            // if (isReload) {
            //     notify.default(Dic.word('wt_all__notification__page_reload'));
            // }

            // Создаем массив промисов из функций, которые нужно выполнить
            const funcPromises = this.actionsAfterDoneQueue
                .filter((func) => typeof func === 'function')
                .map(async (func) => (func as ActionsAfterDoneQueueFunc)());

            // Ожидаем выполнения всех функций
            await Promise.all(funcPromises);

            if (isReload) {
                setTimeout(() => {
                    window?.location.reload();
                }, 1600);
            }

            // Очистка списка действий после их выполнения
            this.actionsAfterDoneQueue = [];
        }
    }

    /**
     * Создает и добавляет задачу в очередь. По умолчанию запускает очередь сразу после добавления
     *
     * @param args - Параметры для создания задачи.
     * @param args.isRunQueueImmediately - Запускать ли очередь сразу. По умолчанию true.
     */
    public createAndAddTask(
        args: CreateTaskArgs &
            Pick<AddTaskAndRunASAPArgs, 'actionAfterDoneQueue'> & {
                isRunQueueImmediately?: boolean;
            },
    ) {
        const { isRunQueueImmediately = true, ...createTaskProps } = args;
        const task = this.createTask(createTaskProps);

        this.addTaskAndRunASAP({ task, isRunQueueImmediately });

        return task;
    }

    /**
     * Добавляет задачу и запускает выполнение задачи как можно быстрее.
     *
     * @param args.task - Задача для добавления и немедленного выполнения.
     * @param args.isRunQueueImmediately - Запускать ли очередь сразу. По умолчанию true.
     */
    public addTaskAndRunASAP(args: AddTaskAndRunASAPArgs) {
        const { isRunQueueImmediately = true, actionAfterDoneQueue, task } = args || {};

        const taskName = task.name;

        taskQLogger.log(
            `addTaskAndRunASAP ${taskName} isRunQueueImmediately=${isRunQueueImmediately} this.isRunning=${this.isRunning}`,
        );

        this.add(task);

        if (actionAfterDoneQueue) {
            this.addActionAfterDoneQueue(actionAfterDoneQueue);
        }

        if (isRunQueueImmediately && !this.isRunning) {
            taskQLogger.log(`Task ${taskName} running ASAP`);
            this.runQueue();
        }
    }

    /**
     * Добавляет новую задачу в очередь согласно ORDER_TASKS.
     * @param task - Задача для добавления в очередь.
     */
    public add(task: Task) {
        if (!this.queue || !this.queue.length) {
            this.queue = [];
        }

        const taskName = task.name;
        const taskOrderIndex = ORDER_TASKS.indexOf(taskName);

        taskQLogger.log('taskName', taskName);

        if (taskOrderIndex === -1) {
            // проверяем есть ли такая задача в ORDER_TASKS
            const errorMessage = `Task ${taskName} is not recognized in ORDER_TASKS`;
            taskQLogger.error(errorMessage);
            throw new Error(errorMessage);
        }

        // Проверяем, существует ли задача с таким же именем в очереди
        if (this.queue.some((qTask) => qTask.name === taskName)) {
            taskQLogger.log(`Task ${taskName} already exists in the queue`);
            return;
        }

        // Вставка задачи в соответствии с ORDER_TASKS
        let insertIndex = this.queue.length;
        // если какая-то задача уже выполняется (всегда должна быть первой т.е. i=0), то мы вместо нее или перед этой задачей уже не можем вставить
        let firstIndex = this.currentTask ? 1 : 0;
        for (firstIndex; firstIndex < this.queue.length; firstIndex++) {
            const currentTaskIndex = ORDER_TASKS.indexOf(this.queue[firstIndex].name);

            if (currentTaskIndex > taskOrderIndex) {
                insertIndex = firstIndex;
                break;
            }
        }

        if (this.queue.length < 1) {
            this.queue = [task];
        } else {
            // вставляем в нужное место по ORDER_TASKS не удаляя ни одну задачу
            this.queue.splice(insertIndex, 0, task);
        }

        taskQLogger.log(`Task ${taskName} added at position ${insertIndex}`);

        taskQLogger.log('Current queue:', this.displayQueue());
    }

    /**
     * Удаляет задачу из очереди по имени.
     *
     * @param taskName - Имя задачи для удаления.
     */
    private remove(taskName: TaskName) {
        const index = this.queue.findIndex((task) => task.name === taskName);
        if (index > -1) {
            taskQLogger.log(`Task ${taskName} found at index ${index}, removing.`);
            this.queue.splice(index, 1);
            taskQLogger.log(`Task ${taskName} removed from queue`);
        } else {
            taskQLogger.log(`Task ${taskName} not found in queue`);
        }

        // Добавляем проверку текущей задачи
        if (this.currentTask?.name === taskName) {
            this.currentTask = null;
        }
    }

    /**
     * Отменяет все задачи в очереди.
     */
    public cancelAll() {
        this.queue = [];
        this.actionsAfterDoneQueue = [];
        // Предполагается, что текущая задача уже завершена или будет прервана
        this.isRunning = false;
        taskQLogger.log('All queue canceled');
    }

    /**
     * Последовательно выполняет задачи из очереди.
     * Задачи выполняются до тех пор, пока очередь не станет пустой.
     * Регистрирует начало и завершение каждой задачи в лог.
     * Обрабатывает ошибки выполнения задач.
     */
    public async runQueue() {
        taskQLogger.log('Running queue =', this.queue, ', is Running =', this.isRunning);
        if (this.isRunning) return;

        this.isRunning = true;

        const recursiveProcessTask = async () => {
            const finishQueue = () => {
                this.isRunning = false;
                this.executeActionsAfterDone();
            };

            if (this.queue.length === 0) {
                taskQLogger.log('Queue is empty');
                finishQueue();
                return;
            }

            const task = this.queue[0];

            if (task) {
                this.currentTask = task;
                try {
                    taskQLogger.log(`Task ${task.name} started`);
                    await task.start();
                    taskQLogger.log(`Task ${task.name} promise resolved`);
                } catch (error) {
                    taskQLogger.error(`Task ${task.name} failed with error: ${error}`, error);
                    task.error();
                    taskQLogger.log(`Task ${task.name} promise rejected`);
                } finally {
                    taskQLogger.log(`Removing task: ${task.name}`);
                    this.remove(task.name);
                    this.currentTask = null;
                    taskQLogger.log('Current queue:', this.displayQueue());
                    // Рекурсивный вызов для обработки следующей задачи
                    await recursiveProcessTask();
                }
            } else {
                taskQLogger.warn('TROUBLE! Task from queue is empty');
                finishQueue();
            }
        };

        await recursiveProcessTask();
    }

    /**
     * Возвращает текущее состояние очереди.
     *
     * @returns Объект, представляющий текущие задачи в очереди.
     */
    public getStatus() {
        return {
            running: this.isRunning,
            queue: this.queue.map((task) => ({
                name: task.name,
            })),
        };
    }

    /**
     * Возвращает строковое представление текущей очереди задач.
     *
     * @returns Список имен задач в очереди в формате JSON.
     */
    private displayQueue() {
        return JSON.stringify(this.queue.map((task) => task.name));
    }
}

export const taskQueue = new TaskQueue();

export { createTaskForQueue as createTask };
