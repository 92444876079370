import type { RouteRegexes } from '../types';

export const routeRegexes = {
  feed: { regexp: /^(?:\/)+(?!\/)feed(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/ },
  communities: {
    regexp: /^(?:\/)+(?!\/)communities(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
  },
  community: {
    regexp:
      /^(?:\/)+(?!\/)feed(?:\/)+(?!\/)community(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['communityId'],
    validators: { communityId: [{ kind: 'number' }] },
  },
  tag: {
    regexp:
      /^(?:\/)+(?!\/)feed(?:\/)+(?!\/)tag(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['tagId'],
    validators: { tagId: [{ kind: 'number' }] },
  },
  shop: { regexp: /^(?:\/)+(?!\/)shop_old(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/ },
  shopProduct: {
    regexp:
      /^(?:\/)+(?!\/)shop_old(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['type', 'slug'],
    validators: {
      type: [
        { kind: 'literal', literalValue: 'product' },
        { kind: 'literal', literalValue: 'service' },
        { kind: 'literal', literalValue: 'course' },
        { kind: 'literal', literalValue: 'offer' },
      ],
      slug: [{ kind: 'string' }],
    },
  },
  shopUserProduct: {
    regexp:
      /^(?:\/)+(?!\/)shop_old(?:\/)+(?!\/)product(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['slug'],
    validators: { slug: [{ kind: 'string' }] },
  },
  shopService: {
    regexp:
      /^(?:\/)+(?!\/)shop_old(?:\/)+(?!\/)service(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['slug'],
    validators: { slug: [{ kind: 'string' }] },
  },
  shopCourse: {
    regexp:
      /^(?:\/)+(?!\/)shop_old(?:\/)+(?!\/)course(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['slug'],
    validators: { slug: [{ kind: 'string' }] },
  },
  shopOffer: {
    regexp:
      /^(?:\/)+(?!\/)shop_old(?:\/)+(?!\/)offer(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['slug'],
    validators: { slug: [{ kind: 'string' }] },
  },
  shop2: {
    regexp: /^(?:\/)+(?!\/)shop(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    validators: {
      productId: [{ kind: 'undefined' }, { kind: 'number' }],
      display: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: 'single' },
      ],
      productType: [
        { kind: 'undefined' },
        { kind: 'null' },
        { kind: 'string' },
      ],
    },
  },
  shop2Catalog: {
    regexp: /^(?:\/)+(?!\/)shop(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    validators: {
      display: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: 'single' },
      ],
      productType: [{ kind: 'string' }],
    },
  },
  shop2Product: {
    regexp: /^(?:\/)+(?!\/)shop(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    validators: {
      productId: [{ kind: 'number' }],
      productType: [
        { kind: 'undefined' },
        { kind: 'null' },
        { kind: 'string' },
      ],
    },
  },
  homeRoot: {
    regexp: /^(?:\/)+(?!\/)home(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    validators: {
      productType: [
        { kind: 'undefined' },
        { kind: 'null' },
        { kind: 'string' },
      ],
    },
  },
  homeFavorites: {
    regexp: /^(?:\/)+(?!\/)home(?:\/)+(?!\/)favorites(?:(?:\/)+(?!\/)$)?$/,
    validators: {
      productType: [
        { kind: 'undefined' },
        { kind: 'null' },
        { kind: 'string' },
      ],
    },
  },
  homeFavorite: {
    regexp:
      /^(?:\/)+(?!\/)home(?:\/)+(?!\/)favorite(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:(?:\/)+(?!\/)$)?$/,
    keys: ['productId'],
    validators: {
      productId: [{ kind: 'number' }],
      productType: [
        { kind: 'undefined' },
        { kind: 'null' },
        { kind: 'string' },
      ],
    },
  },
  homeSubscriptions: {
    regexp: /^(?:\/)+(?!\/)home(?:\/)+(?!\/)subscriptions(?:(?:\/)+(?!\/)$)?$/,
    validators: {
      productType: [
        { kind: 'undefined' },
        { kind: 'null' },
        { kind: 'string' },
      ],
      isArchived: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: false },
        { kind: 'literal', literalValue: true },
      ],
    },
  },
  homeSubscription: {
    regexp:
      /^(?:\/)+(?!\/)home(?:\/)+(?!\/)subscription(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:(?:\/)+(?!\/)$)?$/,
    keys: ['productId'],
    validators: {
      productId: [{ kind: 'number' }],
      productType: [
        { kind: 'undefined' },
        { kind: 'null' },
        { kind: 'string' },
      ],
      result: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: 'success' },
      ],
      type: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: 'contribution' },
        { kind: 'literal', literalValue: 'subscribeWithContribution' },
      ],
    },
  },
  homeProduct: {
    regexp:
      /^(?:\/)+(?!\/)home(?:\/)+(?!\/)product(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:(?:\/)+(?!\/)$)?$/,
    keys: ['productId'],
    validators: {
      productId: [{ kind: 'number' }],
      productType: [
        { kind: 'undefined' },
        { kind: 'null' },
        { kind: 'string' },
      ],
      result: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: 'success' },
      ],
      type: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: 'contribution' },
      ],
    },
  },
  creatorRoot: {
    regexp: /^(?:\/)+(?!\/)creator(?:(?:\/)+(?!\/)$)?$/,
    validators: {
      productType: [
        { kind: 'undefined' },
        { kind: 'null' },
        { kind: 'string' },
      ],
      sendMetricsPlace: [{ kind: 'undefined' }, { kind: 'string' }],
    },
  },
  creatorOverview: {
    regexp:
      /^(?:\/)+(?!\/)creator(?:\/)+(?!\/)overview(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
  },
  creatorMyProducts: {
    regexp:
      /^(?:\/)+(?!\/)creator(?:\/)+(?!\/)products(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
  },
  creatorMyProductsProductType: {
    regexp:
      /^(?:\/)+(?!\/)creator(?:\/)+(?!\/)products(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    validators: { productType: [{ kind: 'string' }] },
  },
  creatorAnalyticsProducts: {
    regexp:
      /^(?:\/)+(?!\/)creator(?:\/)+(?!\/)analytics(?:\/)+(?!\/)products(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
  },
  creatorAnalyticsSocial: {
    regexp:
      /^(?:\/)+(?!\/)creator(?:\/)+(?!\/)analytics(?:\/)+(?!\/)social(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    validators: {
      'analytics-type': [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: 'followers' },
        { kind: 'literal', literalValue: 'likes' },
      ],
    },
  },
  creatorMyProduct: {
    regexp:
      /^(?:\/)+(?!\/)creator(?:\/)+(?!\/)products(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['productType', 'productId'],
    validators: {
      productType: [{ kind: 'string' }],
      productId: [{ kind: 'number' }],
    },
  },
  creatorReviews: {
    regexp: /^(?:\/)+(?!\/)creator(?:\/)+(?!\/)reviews(?:(?:\/)+(?!\/)$)?$/,
    validators: { productId: [{ kind: 'undefined' }, { kind: 'string' }] },
  },
  creatorAi: {
    regexp:
      /^(?:\/)+(?!\/)creator(?:\/)+(?!\/)ai(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    validators: {
      creditsBought: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: false },
        { kind: 'literal', literalValue: true },
      ],
    },
  },
  creatorAiWizard: {
    regexp:
      /^(?:\/)+(?!\/)creator(?:\/)+(?!\/)ai(?:\/)+(?!\/)wizard(?:(?:\/)+(?!\/)((?:(?!\/).)+?))?(?:(?:\/)+(?!\/)$)?$/,
    keys: ['portfolioId'],
    validators: {
      portfolioId: [{ kind: 'number' }],
      isNew: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: false },
        { kind: 'literal', literalValue: true },
      ],
      status: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: 'Created' },
        { kind: 'literal', literalValue: 'InProgress' },
        { kind: 'literal', literalValue: 'Ready' },
      ],
      returnTo: [{ kind: 'undefined' }, { kind: 'number' }],
      step: [{ kind: 'undefined' }, { kind: 'string' }],
      backLink: [{ kind: 'undefined' }, { kind: 'string' }],
    },
  },
  creatorAiPortfolio: {
    regexp:
      /^(?:\/)+(?!\/)creator(?:\/)+(?!\/)ai(?:\/)+(?!\/)portfolio(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['portfolioId'],
    validators: {
      portfolioId: [{ kind: 'number' }],
      creditsBought: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: false },
        { kind: 'literal', literalValue: true },
      ],
    },
  },
  creatorAi2: {
    regexp:
      /^(?:\/)+(?!\/)creator(?:\/)+(?!\/)ai2(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    validators: {
      creditsBought: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: false },
        { kind: 'literal', literalValue: true },
      ],
    },
  },
  creatorAi2Wizard: {
    regexp:
      /^(?:\/)+(?!\/)creator(?:\/)+(?!\/)ai2(?:\/)+(?!\/)wizard(?:(?:\/)+(?!\/)((?:(?!\/).)+?))?(?:(?:\/)+(?!\/)$)?$/,
    keys: ['portfolioId'],
    validators: {
      portfolioId: [{ kind: 'number' }],
      isNew: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: false },
        { kind: 'literal', literalValue: true },
      ],
      status: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: 'Created' },
        { kind: 'literal', literalValue: 'InProgress' },
        { kind: 'literal', literalValue: 'Ready' },
      ],
      returnTo: [{ kind: 'undefined' }, { kind: 'number' }],
      step: [{ kind: 'undefined' }, { kind: 'string' }],
      backLink: [{ kind: 'undefined' }, { kind: 'string' }],
    },
  },
  creatorAi2Portfolio: {
    regexp:
      /^(?:\/)+(?!\/)creator(?:\/)+(?!\/)ai2(?:\/)+(?!\/)portfolio(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['portfolioId'],
    validators: {
      portfolioId: [{ kind: 'number' }],
      creditsBought: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: false },
        { kind: 'literal', literalValue: true },
      ],
    },
  },
  creatorProductsSignals: {
    regexp:
      /^(?:\/)+(?!\/)creator(?:\/)+(?!\/)products(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)signals(?:(?:\/)+(?!\/)$)?$/,
    keys: ['productId'],
    validators: { productId: [{ kind: 'number' }] },
  },
  profileById: {
    regexp:
      /^(?:\/)+(?!\/)profile(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['id'],
    validators: {
      id: [{ kind: 'number' }],
      isAddFeedStatusActive: [
        { kind: 'undefined' },
        { kind: 'literal', literalValue: '' },
      ],
    },
  },
  profileByAlias: {
    regexp:
      /^(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['alias'],
    validators: {
      alias: [
        {
          kind: 'template',
          prefix: '@',
          suffix: '',
          placeholder: [{ kind: 'string' }],
        },
      ],
    },
  },
  profilePremiumById: {
    regexp:
      /^(?:\/)+(?!\/)profile(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)premium(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['id'],
    validators: { id: [{ kind: 'number' }] },
  },
  profilePremiumByAlias: {
    regexp:
      /^(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)premium(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['alias'],
    validators: {
      alias: [
        {
          kind: 'template',
          prefix: '@',
          suffix: '',
          placeholder: [{ kind: 'string' }],
        },
      ],
    },
  },
  pinnedPostById: {
    regexp:
      /^(?:\/)+(?!\/)profile(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['personId', 'pinnedPostId'],
    validators: {
      personId: [{ kind: 'number' }],
      pinnedPostId: [{ kind: 'number' }],
    },
  },
  pinnedPostFullById: {
    regexp:
      /^(?:\/)+(?!\/)profile(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)full(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['personId', 'pinnedPostId'],
    validators: {
      personId: [{ kind: 'number' }],
      pinnedPostId: [{ kind: 'number' }],
    },
  },
  pinnedPremiumPostById: {
    regexp:
      /^(?:\/)+(?!\/)profile(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)premium(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['personId', 'pinnedPostId'],
    validators: {
      personId: [{ kind: 'number' }],
      pinnedPostId: [{ kind: 'number' }],
    },
  },
  pinnedPremiumPostFullById: {
    regexp:
      /^(?:\/)+(?!\/)profile(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)premium(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)full(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['personId', 'pinnedPostId'],
    validators: {
      personId: [{ kind: 'number' }],
      pinnedPostId: [{ kind: 'number' }],
    },
  },
  pinnedPostByAlias: {
    regexp:
      /^(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['alias', 'pinnedPostId'],
    validators: {
      alias: [
        {
          kind: 'template',
          prefix: '@',
          suffix: '',
          placeholder: [{ kind: 'string' }],
        },
      ],
      pinnedPostId: [{ kind: 'number' }],
    },
  },
  pinnedPostFullByAlias: {
    regexp:
      /^(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)full(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['alias', 'pinnedPostId'],
    validators: {
      alias: [
        {
          kind: 'template',
          prefix: '@',
          suffix: '',
          placeholder: [{ kind: 'string' }],
        },
      ],
      pinnedPostId: [{ kind: 'number' }],
    },
  },
  pinnedPremiumPostByAlias: {
    regexp:
      /^(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)premium(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['alias', 'pinnedPostId'],
    validators: {
      alias: [
        {
          kind: 'template',
          prefix: '@',
          suffix: '',
          placeholder: [{ kind: 'string' }],
        },
      ],
      pinnedPostId: [{ kind: 'number' }],
    },
  },
  pinnedPremiumPostFullByAlias: {
    regexp:
      /^(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)premium(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)full(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['alias', 'pinnedPostId'],
    validators: {
      alias: [
        {
          kind: 'template',
          prefix: '@',
          suffix: '',
          placeholder: [{ kind: 'string' }],
        },
      ],
      pinnedPostId: [{ kind: 'number' }],
    },
  },
  profileLikesById: {
    regexp:
      /^(?:\/)+(?!\/)profile(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)likes(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['id'],
    validators: { id: [{ kind: 'number' }] },
  },
  profileLikesByAlias: {
    regexp:
      /^(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)likes(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['alias'],
    validators: {
      alias: [
        {
          kind: 'template',
          prefix: '@',
          suffix: '',
          placeholder: [{ kind: 'string' }],
        },
      ],
    },
  },
  profileProductsById: {
    regexp:
      /^(?:\/)+(?!\/)profile(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)products(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['id'],
    validators: { id: [{ kind: 'number' }] },
  },
  profileProductsByAlias: {
    regexp:
      /^(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)products(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    keys: ['alias'],
    validators: {
      alias: [
        {
          kind: 'template',
          prefix: '@',
          suffix: '',
          placeholder: [{ kind: 'string' }],
        },
      ],
    },
  },
  cabinetRoot: {
    regexp: /^(?:\/)+(?!\/)settings(?:\/)+(?!\/)(?:(?:\/)+(?!\/)$)?$/,
    validators: {
      productType: [
        { kind: 'undefined' },
        { kind: 'null' },
        { kind: 'string' },
      ],
    },
  },
  cabinetMonetization: {
    regexp:
      /^(?:\/)+(?!\/)settings(?:\/)+(?!\/)monetization(?:(?:\/)+(?!\/)$)?$/,
  },
  cabinetSecurity: {
    regexp: /^(?:\/)+(?!\/)settings(?:\/)+(?!\/)security(?:(?:\/)+(?!\/)$)?$/,
  },
  cabinetSubscriptions: {
    regexp:
      /^(?:\/)+(?!\/)settings(?:\/)+(?!\/)subscriptions(?:(?:\/)+(?!\/)$)?$/,
  },
  cabinetNotifications: {
    regexp:
      /^(?:\/)+(?!\/)settings(?:\/)+(?!\/)notifications(?:(?:\/)+(?!\/)$)?$/,
    validators: {
      unsubscribeKey: [{ kind: 'undefined' }, { kind: 'string' }],
      template: [{ kind: 'undefined' }, { kind: 'string' }],
      mailLogId: [{ kind: 'undefined' }, { kind: 'string' }],
    },
  },
  cabinetBlacklist: {
    regexp: /^(?:\/)+(?!\/)settings(?:\/)+(?!\/)blacklist(?:(?:\/)+(?!\/)$)?$/,
  },
  cabinetReferral: {
    regexp: /^(?:\/)+(?!\/)settings(?:\/)+(?!\/)referral(?:(?:\/)+(?!\/)$)?$/,
  },
  instrument: {
    regexp: /^(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:(?:\/)+(?!\/)$)?$/,
    keys: ['ticker'],
    validators: {
      ticker: [
        {
          kind: 'template',
          prefix: '$',
          suffix: '',
          placeholder: [{ kind: 'string' }],
        },
      ],
      id: [{ kind: 'undefined' }, { kind: 'number' }],
    },
  },
  instrumentPinned: {
    regexp:
      /^(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:(?:\/)+(?!\/)$)?$/,
    keys: ['ticker', 'pinnedPostId'],
    validators: {
      ticker: [
        {
          kind: 'template',
          prefix: '$',
          suffix: '',
          placeholder: [{ kind: 'string' }],
        },
      ],
      pinnedPostId: [{ kind: 'number' }],
      id: [{ kind: 'undefined' }, { kind: 'number' }],
    },
  },
  instrumentPinnedFull: {
    regexp:
      /^(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)(?:((?:(?!\/).)+?))(?:\/)+(?!\/)full(?:(?:\/)+(?!\/)$)?$/,
    keys: ['ticker', 'pinnedPostId'],
    validators: {
      ticker: [
        {
          kind: 'template',
          prefix: '$',
          suffix: '',
          placeholder: [{ kind: 'string' }],
        },
      ],
      pinnedPostId: [{ kind: 'number' }],
      id: [{ kind: 'undefined' }, { kind: 'number' }],
    },
  },
} as RouteRegexes;
