// @ts-nocheck
// не знаю как это поправить, возможно поможет перевод проекта на ESM или на ts5

import { baseClass } from '@finam/web-ui-kit-core/theme/base';
import { defaultLayoutClass } from '@finam/web-ui-kit-core/theme/layout';
import {
    defaultLayoutClass as limexDefaultLayoutClass,
    lightModeClass as limexLightModeClass,
} from '@finam/web-ui-kit-core/theme/limex';

// eslint-disable-next-line no-restricted-imports
export { tokens } from '@finam/web-ui-kit-core/theme/contract';

export const initFinamUIKit = () => {
    document.body.classList.add(baseClass, defaultLayoutClass, limexDefaultLayoutClass, limexLightModeClass);
};
