import { css } from 'styled-components';

import { boxShadowLikeBorder } from 'app/styled';
import { buttonLargeFontStyle, buttonMediumFontStyle, buttonSmallFontStyle } from 'services/typography';
import { tokens } from 'services/tokens';

import { ButtonKind, ButtonWrapperProps, SizeMap, StateMap } from '../types';

function getIconPaddingCompensation(direction: 'left' | 'right') {
    return ({ iconPos }: ButtonWrapperProps) =>
        // Subtract optical compensation from the icon's lateral padding
        iconPos === direction
            ? 'calc(var(--padding-horizontal) - var(--padding-icon-compensation))'
            : 'var(--padding-horizontal)';
}

const { spacing } = tokens;

/** Button kind-dependent CSS */
const getStateMap = (kind: ButtonKind): StateMap => {
    const {
        colors: {
            button: { bg, label, border },
            border: borderCommon,
        },
    } = tokens;
    const primary = {
        default: `
            background: ${bg.primary.negative.default};
            color: ${label.negative.default.default};
        `,
        hover: `
            background: ${bg.primary.negative.hover};
            color: ${label.negative.default.default};
        `,
        focus: `
            background: ${bg.primary.negative.default};
            color: ${label.negative.default.default};
            box-shadow: ${boxShadowLikeBorder('3px', borderCommon.negative.default.focus)};
        `,
        active: `
            background: ${bg.primary.negative.pressed};
            color: ${label.negative.default.default};
        `,
        disabled: `
            background: ${bg.primary.default.disabled};
            color: ${label.default.default.disabled};
        `,
    };
    const kindMap: Record<ButtonKind, StateMap> = {
        primary,
        secondary: {
            default: `
                background: ${bg.secondary.negative.default};
                color: ${label.secondary.negative.default};
            `,
            hover: `
                background: ${bg.secondary.negative.hover};
                color: ${label.secondary.negative.hover};
            `,
            focus: `
                background: ${bg.secondary.negative.default};
                color: ${label.secondary.negative.default};
                box-shadow: ${boxShadowLikeBorder('3px', borderCommon.negative.default.focus)};
            `,
            active: `
                background: ${bg.secondary.negative.pressed};
                color: ${label.secondary.negative.pressed};
            `,
            disabled: `
                background: ${bg.secondary.default.disabled};
                color: ${label.default.default.disabled};
            `,
        },
        outline: {
            default: `
                background: none;
                color: ${label.secondary.negative.default};
                box-shadow: ${boxShadowLikeBorder('1px', border.outline.negative.default)};
            `,
            hover: `
                background: ${bg.outline.negative.hover};
                color: ${label.secondary.negative.hover};
                box-shadow: ${boxShadowLikeBorder('1px', border.outline.negative.hover)};
            `,
            focus: `
                background: none;
                color: ${label.secondary.default.default};
                box-shadow: ${boxShadowLikeBorder('3px', borderCommon.negative.default.focus)};
            `,
            active: `
                background: ${bg.outline.negative.pressed};
                color: ${label.secondary.negative.pressed};
                box-shadow: ${boxShadowLikeBorder('1px', border.outline.negative.pressed)};
            `,
            disabled: `
                background: none;
                color: ${label.default.default.disabled};
                box-shadow: ${boxShadowLikeBorder('1px', border.default.default.disabled)};
            `,
        },
        'ghost-primary': primary,
        'ghost-secondary': primary,
        'ondark-secondary': primary,
        'ondark-outline': primary,
        'onlight-outline': primary,
        cancel: primary,
    };
    return kindMap[kind];
};

/** Button size-dependent CSS */
export const sizeMap: SizeMap = {
    large: css`
        ${buttonLargeFontStyle};
        --padding-vertical: ${spacing.m};
        --padding-horizontal: ${spacing.xl};
    `,
    medium: css`
        ${buttonMediumFontStyle};
        --padding-vertical: ${spacing.s};
        --padding-horizontal: ${spacing.m};
    `,
    small: css`
        ${buttonSmallFontStyle};
        --padding-vertical: ${spacing.buttonS.padding};
        --padding-horizontal: ${spacing.s};
    `,
    tiny: css`
        ${buttonSmallFontStyle};
        --padding-vertical: ${spacing.buttonXS.padding};
        --padding-horizontal: ${spacing.buttonXS.padding};
    `,
};

export const iconGapSizeMap: SizeMap = {
    large: css``,
    medium: css``,
    small: css``,
    tiny: css``,
};

export const paddingLeftRightStyle = ({ icon, text, isProcessing }: ButtonWrapperProps) => {
    const hasIconAndText = icon && text;
    const hasOnlyIcon = (icon && !text) || (isProcessing && !text);
    const hasOnlyText = !icon && text;

    if (hasIconAndText) {
        return css`
            padding-left: ${getIconPaddingCompensation('left')};
            padding-right: ${getIconPaddingCompensation('right')};
        `;
    }

    if (hasOnlyIcon) {
        return `
            padding-left: var(--padding-vertical);
            padding-right: var(--padding-vertical);
        `;
    }

    if (hasOnlyText) {
        return `
            padding-left: var(--padding-horizontal);
            padding-right: var(--padding-horizontal);
        `;
    }

    return undefined;
};

export const contentContainerStyle = ({ icon, text, iconPos }: ButtonWrapperProps) => {
    const hasIconAndText = icon && text;
    const hasOnlyIcon = icon && !text;
    const hasOnlyText = !icon && text;

    if (hasIconAndText) {
        return iconPos === 'right'
            ? `
                grid-template-columns: min-content min-content;
                grid-template-areas: "content icon";
            `
            : `
                grid-template-columns: min-content min-content;
                grid-template-areas: "icon content";
            `;
    }

    if (hasOnlyIcon) {
        return `
            grid-template-columns: min-content;
            grid-template-areas: "icon";
        `;
    }

    if (hasOnlyText) {
        return `
            grid-template-columns: min-content;
            grid-template-areas: "content";
        `;
    }

    return '';
};

export const typeNegative = {
    wrapperStyle: css<ButtonWrapperProps>`
        --padding-icon-compensation: ${({ icon, text }) => (icon && text ? '2px' : '0px')};
    `,
    sizeMap,
    paddingLeftRightStyle,
    getStateMap,
    contentContainerStyle,
    iconGapSizeMap,
};
