import React, { SyntheticEvent } from 'react';

import { ProcessingIcon } from 'components/icons2/ProcessingIcon';

import { ButtonProps } from './types';
import { ButtonContentContainer, ButtonTextContainer, ButtonWrapper, ProcessingIconContainer } from './styled';

// MARK: - Types

/** A tag name of the element used to render the button */
type ButtonTagName = 'a' | 'button';

/** Props for buttons using an <a /> element */
export type LinkButtonProps = ButtonProps & {
    href: string;
    target?: string;
    download?: boolean;
};

export type SmartButtonProps = ButtonProps | LinkButtonProps;

export type { ButtonProps };

/** Props for polymorphic button implementation component */
type ButtonImplementationProps<TagName extends ButtonTagName> = (ButtonProps & Partial<LinkButtonProps>) & {
    /** A tag name of the button element. Default - "button" */
    tagName: TagName;
};

// MARK: - Common Implementation Component

const ButtonImplementation = React.forwardRef(
    <TagName extends ButtonTagName>(props: ButtonImplementationProps<TagName>, ref: React.ForwardedRef<unknown>) => {
        const {
            className: classNameProp,
            tagName,
            children: text,
            title,
            size = 'medium',
            kind = 'primary',
            type = 'standard',
            nativeType,
            isProcessing,
            isDisabled,
            isPressed,
            icon: Icon,
            iconSize = 24,
            iconPos = 'left',
            onClick: onClickProp,
            block,
            href,
            target = '_self',
            download,
            withTooltipWhenDisabled = false,
        } = props;
        const onClick = isDisabled || isProcessing ? preventDefaultHandler : onClickProp;
        const linkProps = tagName === 'a' ? { href, target, download } : {};
        const className = [
            classNameProp,
            'autotest__button',
            isDisabled && 'autotest__button-disabled',
            isProcessing && 'autotest__button-processing',
            isPressed && 'autotest__button-pressed',
        ]
            .filter((cn) => cn)
            .join(' ');

        return (
            <ButtonWrapper
                ref={ref}
                as={tagName as unknown as undefined}
                className={className}
                size={size}
                type={nativeType}
                customType={type}
                kind={kind}
                text={text}
                isDisabled={isDisabled}
                aria-disabled={isDisabled}
                isProcessing={isProcessing}
                isPressed={isPressed}
                icon={Icon}
                iconPos={iconPos}
                onClick={onClick}
                block={block}
                title={title}
                {...linkProps}
                withTooltipWhenDisabled={withTooltipWhenDisabled}
            >
                <div tabIndex={-1}>
                    {isProcessing && (
                        <ProcessingIconContainer>
                            <ProcessingIcon size={24} />
                        </ProcessingIconContainer>
                    )}
                    <ButtonContentContainer style={{ visibility: isProcessing ? 'hidden' : 'visible' }}>
                        {Icon && <Icon size={iconSize} />}
                        {text && <ButtonTextContainer>{text}</ButtonTextContainer>}
                    </ButtonContentContainer>
                </div>
            </ButtonWrapper>
        );
    },
);

// MARK: - Element-specific Components

export const Button = React.forwardRef((props: ButtonProps, ref) => (
    <ButtonImplementation {...props} ref={ref} tagName="button" />
));

export const LinkButton = React.forwardRef((props: LinkButtonProps, ref) => (
    <ButtonImplementation {...props} ref={ref} tagName="a" />
));

export const SmartButton = React.forwardRef((props: SmartButtonProps, ref) => {
    if ('href' in props) {
        return <LinkButton {...props} ref={ref} />;
    }

    return <Button {...props} ref={ref} />;
});

export { ProcessingIconContainer };

// MARK: - Helpers

function preventDefaultHandler(e: SyntheticEvent) {
    e.preventDefault();
}
