import Deferred from 'services/utils/deferred';

import { CreateTaskArgs, Task } from './types';

/**
 * Создает новую задачу с заданным именем, функцией запуска и функцией завершения.
 *
 * @param args - Объект с параметрами для создания задачи.
 * @param args.name - Имя задачи.
 * @param args.onStart - Функция, которая запускает выполнение задачи.
 * @param args.onFinish - (Необязательно) Функция, которая вызывается при завершении задачи.
 * @returns Новый объект задачи.
 */
export function createTask(args: CreateTaskArgs): Task {
    const { name, onStart, onFinish, onError } = args;

    const defer = new Deferred();

    return {
        name,
        start: async () => {
            onStart();
            await defer.promise;
        },
        cancel: () => {
            defer.resolve(true);
        },
        error: () => {
            defer.reject();
            onError?.();
        },
        finish: () => {
            defer.resolve(true);
            onFinish?.();
        },
    };
}
