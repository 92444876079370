import { TaskName } from './types';

/**
 * порядок выполнения в очереди по ключу попапа
 * описание каждого попапа в README
 */
export const ORDER_TASKS: Array<TaskName> = [
    'CookiePrivacySettingsPopup',
    'GaWindow',
    'GAUserIdentifiers',
    'GAAgreements',
    'GALimeAuthorization',
    'InterestsPopup',
    'DemoAccountSuccessPopup',
    'DemoAccountSuccessPopupAccountsFog',
];
