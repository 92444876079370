import React from 'react';

import { ArrowLeftMiniIcon } from 'components/icons2/ArrowLeftMiniIcon';

import { DEFAULT_SIZE_WT_POPUP_3 } from '../constants';

import * as Styled from './styled';
import { StepsWtPopup3PropsType } from './types';

export const Steps = (props: StepsWtPopup3PropsType) => {
    const { onBack, size = DEFAULT_SIZE_WT_POPUP_3, backBtnIsDisabled = false, textBtnBack, textStatusSteps } = props;

    const isDisabledArrowLeft = backBtnIsDisabled || !onBack;

    return (
        <Styled.StepsWrapper size={size}>
            <Styled.ButtonArrowLeft
                size="tiny"
                kind="ghost-secondary"
                icon={ArrowLeftMiniIcon}
                onClick={onBack}
                isDisabled={isDisabledArrowLeft}
            >
                {textBtnBack}
            </Styled.ButtonArrowLeft>
            <Styled.Text>{textStatusSteps}</Styled.Text>
        </Styled.StepsWrapper>
    );
};
