import React, { useMemo } from 'react';

import { useDicMemo } from 'hooks/useDictionary';
import { useExternalLinks } from 'hooks/useExternalLinks';
import { useDomainLcid } from 'hooks/useDomainLcid';

import { PrivacyPolicyLink } from './styled';
import { useGetControlDataCookiePopupWidget } from './hooks/useGetControlDataCookiePopupWidget';
import { CookiePrivacySettingsPopupUI } from './components/CookiePrivacySettingsPopupUI';

export const CookiePrivacySettingsPopup = () => {
    const { rejectAll, acceptAll, isShow } = useGetControlDataCookiePopupWidget();

    const links = useExternalLinks();
    const domainLcid = useDomainLcid();

    const cookiePopupTexts = useDicMemo((dic) => ({
        common: {
            title: dic.word('wt_all__widget_cookie_popup__common__title'),
            btnTexts: {
                acceptAll: dic.word('wt_all__widget_cookie_popup__common__btn__accept'),
                reject: dic.word('wt_all__widget_cookie_popup__common__btn__reject'),
            },
            content: dic.parts('wt_all__widget_cookie_popup__common__content'),
        },
    }));

    const {
        common: { title: titleCommon, btnTexts: btnTextsCommon, content: commonContentParts },
    } = cookiePopupTexts;

    const contentCommon = useMemo(() => {
        const commonContentPrivacyPolicyLink = links.tildaInfoLimexMePrivacyPolicy(domainLcid);
        const [mainText, linkText, endText] = commonContentParts || [];

        return (
            <div>
                {mainText ? <span>{mainText}</span> : null}
                {commonContentPrivacyPolicyLink && linkText ? (
                    <>
                        {mainText ? <>&nbsp;</> : null}
                        <PrivacyPolicyLink href={commonContentPrivacyPolicyLink}>{linkText}</PrivacyPolicyLink>
                    </>
                ) : null}
                {endText || null}
            </div>
        );
    }, [commonContentParts, links, domainLcid]);

    if (!isShow) return null;

    return (
        <CookiePrivacySettingsPopupUI
            reject={rejectAll}
            acceptAll={acceptAll}
            common={{
                title: titleCommon,
                content: contentCommon,
                btnTexts: btnTextsCommon,
            }}
        />
    );
};
