import { getCurrentUser } from 'services/wt-page-helper';
import { backendClient } from 'services/backend-client';
import { sendGAFromDicByPlace } from 'services/analytics-outside/google-analytics';

import { isJustRegistered } from './constants';

export const makeLogin = async (token: string) => {
    const currentUser = await getCurrentUser();
    if (currentUser?.isAuth) return;
    await backendClient.post(
        '/ng/user/kratos/authenticate/',
        {},
        {
            headers: {
                'X-Auth-Token': token,
            },
        },
    );

    sendGAFromDicByPlace({ place: 'service__global_auth_provide__make_login__success' });

    if (!isJustRegistered()) {
        window.location.reload();
    }
};
