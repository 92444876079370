import { CurrentUser, Id } from 'types';
import { PermissionExporter, UserExporter } from 'services/api-services/generated_limex';

export class User implements CurrentUser {
    public readonly channel?: { user: string };

    public readonly counters: { followers: number; following: number; globalInfluence: number };

    public readonly countryCode: string;

    public readonly countryName: string;

    public readonly cover: string | null;

    public readonly created: string;

    public readonly description: string | null;

    public readonly displayName: string;

    public readonly isDemoAccountNotification: boolean;

    public readonly email?: string | null;

    public readonly firstName: string;

    public readonly hasAvatar: boolean;

    public readonly hasEmail?: boolean;

    public readonly id: number;

    public readonly image?: string | null;

    public readonly images: {
        small?: string | null;
        big?: string | null;
        medium?: string | null;
        base?: string | null;
    };

    public readonly inviteLink?: string | null;

    public readonly isActive: boolean;

    public readonly isBlockedByCurrentUser: boolean;

    public readonly isBlockedByGivenUser: boolean;

    public readonly isBlogAdmin?: boolean;

    public readonly isDeveloper?: boolean;

    public readonly isFollowable?: boolean;

    public readonly isMale: boolean;

    public readonly isVerified: boolean;

    public readonly isVerifiedAuthor?: boolean;

    public readonly lastName: string;

    public readonly lastVisit: number | null;

    public readonly lcid: 'en' | 'ru';

    public readonly domainLcid: 'en' | 'ru' | null;

    public readonly objectPermissions: Array<PermissionExporter> | null;

    public readonly occupation: string | null;

    public readonly personalAlias: string | null;

    public readonly shareUrl: string;

    public readonly kratosId?: string | null;

    public readonly popupShown: UserExporter['popupShown'];

    public readonly socialNets?: {
        facebook?: string | null;
        vkontakte?: string | null;
        instagram?: string | null;
        telegram?: string | null;
        personalSite?: string | null;
    };

    public readonly type: UserExporter['type'];

    public readonly url: string;

    public readonly data: {
        subscribed?: boolean | null;
        inBlackList?: boolean | null;
        premiumSubscribed?: boolean | null;
        popupShown?: UserExporter['popupShown'];
    };

    constructor(data: CurrentUser) {
        this.channel = data.channel;
        this.counters = data.counters;
        this.countryCode = data.countryCode;
        this.countryName = data.countryName;
        this.cover = data.cover;
        this.created = data.created;
        this.description = data.description;
        this.displayName = data.displayName;
        this.email = data.email;
        this.firstName = data.firstName;
        this.hasAvatar = data.hasAvatar;
        this.hasEmail = data.hasEmail;
        this.isDemoAccountNotification = data.isDemoAccountNotification;
        this.id = data.id;
        this.image = data.image;
        this.images = data.images;
        this.inviteLink = data.inviteLink;
        this.isActive = data.isActive;
        this.isBlockedByCurrentUser = data.isBlockedByCurrentUser;
        this.isBlockedByGivenUser = data.isBlockedByGivenUser;
        this.isBlogAdmin = data.isBlogAdmin;
        this.isDeveloper = data.isDeveloper;
        this.isFollowable = data.isFollowable;
        this.isMale = data.isMale;
        this.isVerified = data.isVerified;
        this.isVerifiedAuthor = data.isVerifiedAuthor;
        this.lastName = data.lastName;
        this.lastVisit = data.lastVisit;
        this.lcid = data.lcid;
        this.domainLcid = data?.domainLcid || null;
        this.objectPermissions = data.objectPermissions;
        this.occupation = data.occupation;
        this.personalAlias = data.personalAlias;
        this.shareUrl = data.shareUrl;
        this.socialNets = data.socialNets;
        this.type = data.type;
        this.url = data.url;
        this.data = data.data;
        this.kratosId = data.kratosId;
        this.popupShown = {
            isDemoExpiredPopUpShown: data.popupShown?.isDemoExpiredPopUpShown || false,
            isLimeAccountCongratulationShown: data.popupShown?.isLimeAccountCongratulationShown || false,
            isLimeAccountConnectionFailShown: data.popupShown?.isLimeAccountConnectionFailShown || false,
            isCopytradingLandingShown: data.popupShown?.isCopytradingLandingShown || false,
        };
    }

    public get isAuth(): boolean {
        return Boolean(this.id);
    }

    public isSame(idOrUser: CurrentUser | User | Id): boolean {
        if (!this.isAuth) {
            return false;
        }

        if (typeof idOrUser === 'string' || typeof idOrUser === 'number') {
            return this.id === idOrUser;
        }

        return this.id === idOrUser.id;
    }
}
