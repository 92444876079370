import { TypeTxGlobalAuth } from 'types';
import Deferred from 'services/utils/deferred';
import { sendGAFromDicByPlace } from 'services/analytics-outside/google-analytics';

import { LOCAL_STORAGE_JUST_REG_KEY, isJustRegistered } from './constants';
import { gaProviderLogger } from './logger';

export const handleRegistration = (txGlobalAuth: TypeTxGlobalAuth, loginDefer: Deferred<boolean>) => {
    txGlobalAuth.subscribeGlobal('registration', () => {
        gaProviderLogger.log('Was Registration, no reload yet');
        localStorage.setItem(LOCAL_STORAGE_JUST_REG_KEY, 'true');
        sendGAFromDicByPlace({ place: 'service__global_auth_provide__handle_registration' });
    });
    txGlobalAuth.subscribeGlobal('registration', () => {
        txGlobalAuth.subscribeGlobal('finish', async () => {
            await loginDefer.promise;
            sendGAFromDicByPlace({ place: 'service__global_auth_provide__handle_registration__finish' });
            window.location.reload();
        });
    });
    if (isJustRegistered()) {
        gaProviderLogger.log('Is is JUST_REG after reload');
        localStorage.removeItem(LOCAL_STORAGE_JUST_REG_KEY);
        // глобальное событие только для ТЕКУЩЕЙ вкладки
        window.postMessage('limex__user_filled_data');
    }
};
