/* eslint-disable @typescript-eslint/no-explicit-any */
import { CompileOptions } from 'path-to-regexp';
import memoize from 'lodash/memoize';

import { routes } from './generated/routes';
import { getBuildFunction } from './build';
import type { Links } from './types';

/**
 * Функция getLinks создает объект с функциями для построения URL для каждого маршрута.
 */
export const getLinks = memoize(() => {
    // Получаем функцию для построения URL
    const build = getBuildFunction();

    // Проходим по каждому маршруту и создаем функции для построения соответствующих URL.
    return Object.entries(routes).reduce((acc, [key, value]) => {
        // Создаем функцию для построения URL с опциональными параметрами и опциями компиляции.
        acc[key] = (params?: Record<string, string | number | undefined | null>, options?: CompileOptions) =>
            build(value, params, options);

        // Добавляем метод withBaseUrl, который возвращает абсолютный URL с базовым URL сайта.
        acc[key].withBaseUrl = (...args: any[]) => window.location.origin + acc[key](...args);

        return acc;
    }, {} as any) as Links;
});
