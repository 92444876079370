import { isProd } from 'services/utils/env';
import { usePageContext } from 'hooks/usePageContext';

export const useDomainLcid = () => {
    const [{ domainLcid }] = usePageContext();
    return domainLcid;
};

export const useIsRuDomain = () => {
    const domainLcid = useDomainLcid();
    return domainLcid === 'ru' && (!isProd() || location.search.includes('useRu'));
};
