import type { FunctionReturnTypes } from '../types';
import type { Routes } from '../index';

export const routes = {
  feed: '/feed/',
  communities: '/communities/',
  community: '/feed/community/:communityId/',
  tag: '/feed/tag/:tagId/',
  shop: '/shop_old/',
  shopProduct: '/shop_old/:type/:slug/',
  shopUserProduct: '/shop_old/product/:slug/',
  shopService: '/shop_old/service/:slug/',
  shopCourse: '/shop_old/course/:slug/',
  shopOffer: '/shop_old/offer/:slug/',
  shop2: '/shop/',
  shop2Catalog: '/shop/',
  shop2Product: '/shop/',
  homeRoot: '/home/',
  homeFavorites: '/home/favorites',
  homeFavorite: '/home/favorite/:productId',
  homeSubscriptions: '/home/subscriptions',
  homeSubscription: '/home/subscription/:productId',
  homeProduct: '/home/product/:productId',
  creatorRoot: '/creator',
  creatorOverview: '/creator/overview/',
  creatorMyProducts: '/creator/products/',
  creatorMyProductsProductType: '/creator/products/',
  creatorAnalyticsProducts: '/creator/analytics/products/',
  creatorAnalyticsSocial: '/creator/analytics/social/',
  creatorMyProduct: '/creator/products/:productType/:productId/',
  creatorReviews: '/creator/reviews',
  creatorAi: '/creator/ai/',
  creatorAiWizard: '/creator/ai/wizard/:portfolioId?',
  creatorAiPortfolio: '/creator/ai/portfolio/:portfolioId/',
  creatorAi2: '/creator/ai2/',
  creatorAi2Wizard: '/creator/ai2/wizard/:portfolioId?',
  creatorAi2Portfolio: '/creator/ai2/portfolio/:portfolioId/',
  creatorProductsSignals: '/creator/products/:productId/signals',
  profileById: '/profile/:id/',
  profileByAlias: '/:alias/',
  profilePremiumById: '/profile/:id/premium/',
  profilePremiumByAlias: '/:alias/premium/',
  pinnedPostById: '/profile/:personId/:pinnedPostId/',
  pinnedPostFullById: '/profile/:personId/:pinnedPostId/full/',
  pinnedPremiumPostById: '/profile/:personId/premium/:pinnedPostId/',
  pinnedPremiumPostFullById: '/profile/:personId/premium/:pinnedPostId/full/',
  pinnedPostByAlias: '/:alias/:pinnedPostId/',
  pinnedPostFullByAlias: '/:alias/:pinnedPostId/full/',
  pinnedPremiumPostByAlias: '/:alias/premium/:pinnedPostId/',
  pinnedPremiumPostFullByAlias: '/:alias/premium/:pinnedPostId/full/',
  profileLikesById: '/profile/:id/likes/',
  profileLikesByAlias: '/:alias/likes/',
  profileProductsById: '/profile/:id/products/',
  profileProductsByAlias: '/:alias/products/',
  cabinetRoot: '/settings/',
  cabinetMonetization: '/settings/monetization',
  cabinetSecurity: '/settings/security',
  cabinetSubscriptions: '/settings/subscriptions',
  cabinetNotifications: '/settings/notifications',
  cabinetBlacklist: '/settings/blacklist',
  cabinetReferral: '/settings/referral',
  instrument: '/:ticker',
  instrumentPinned: '/:ticker/:pinnedPostId',
  instrumentPinnedFull: '/:ticker/:pinnedPostId/full',
} as FunctionReturnTypes<Routes>;
