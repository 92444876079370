import { createElement, createRef, Fragment, ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';
import { produce } from 'immer';
import { createRoot, Root } from 'react-dom/client';

import { CurrentUser } from 'types';
import { createCurrentUserContext, guestUser } from 'context/current-user';
import { PageContextProvider } from 'context/page';
import { LocaleProvider } from 'context/locale';
import { PopupRootContextProvider } from 'context/popup-root';
import { PopoverRootContextProvider } from 'context/popover-root';
import UrlProcessor from 'services/url-processor';
import Dic from 'services/dictionary';
import notify from 'services/notify';
import WtLogger from 'services/utils/wt-logger';
import { initGoogleAnalytics } from 'services/analytics-outside/google-analytics';
import { ThemeName, themes } from 'services/theme';
import { linkUser, processBuidMetrics, wtUserMetricsLoadOrUpdate } from 'services/user-metrics';
import { sentryClient } from 'services/sentry';
import { NotifyProcess } from 'services/notify/processor';
import { MockHandler, MockServiceWorker } from 'services/msw/browser';
import { mswLoader } from 'services/msw/loader';
import { createTask, taskQueue } from 'services/task-queue';
import { User } from 'services/user';
import { cookiePrivacySettings } from 'services/cookie-privacy-settings';
import { initFinamUIKit } from 'services/tokens';
import { initTxGa, provideTxGA } from 'services/global-auth-provider';
import { isProd } from 'services/utils/env';
import { initializePostHog, posthogIdentifyUser } from 'services/analytics-outside/posthog';
import { CookiePrivacySettingsPopup } from 'widgets/cookie-privacy-settings-popup';
import { ErrorBoundary } from 'widgets/error-boundary';

import { initGoogleTagManagerScript } from '../analytics-outside/google-tag-manager';

import { EmptyExtension, InitialPageSettings, InitPageConfiguration, Page } from './types';

initFinamUIKit();

/**
 * Returns an object used to initialize the page and render the React application.
 *
 * Note, that you __must__ define `WT.Page.initialSettings` on the HTML side for the initialization to work.
 *
 * @example
 * // index.html
 * <script>
 * if (!window.WT) window.WT = {};
 * if (!WT.Page) WT.Page = {};
 *
 * WT.Page.initialSettings = {
 *   currentUser: { ... },
 *   pageContext: {
 *     csrf: '',
 *     googleAnalytics: {
 *       trackingId: '...',
 *       googleAnalyticsId: '...'
 *     },
 *   },
 *     data: {}
 * };
 *
 * if (WT.Page.saveAndRenderIfNeeded) {
 *   WT.Page.saveAndRenderIfNeeded();
 * }
 * </script>
 *
 * // page/index.ts
 * const page = createPage<MarketpaceData>('Marketplace', {
 *   mountPoint: () => document.getElementById('marketplacePage'),
 *   urlMap,
 *   dicwords,
 * }, () => {
 *   return (
 *     <ReduxProvider store={store}>
 *       <MarketplaceApp />
 *     </ReduxProvider>
 *   );
 * });
 *
 * page.saveAndRenderIfNeeded();
 */
export function createPage<ServerData, InitialSettingsExtension extends Record<string, unknown> = EmptyExtension>(
    pageId: string,
    configuration: InitPageConfiguration,
    renderRootNode: (initialSettings: InitialPageSettings<ServerData, InitialSettingsExtension>) => ReactElement,
    params?: {
        googleTagManagerAdditional: () => void;
    },
): Page<ServerData, InitialSettingsExtension> {
    type ThisPage = Page<ServerData, InitialSettingsExtension>;
    type ThisPageSettings = InitialPageSettings<ServerData, InitialSettingsExtension> & {
        currentUser: User;
        disabledGA?: boolean;
    };

    const { urlMap, mountPoint: getMountPoint, mockHandlers: getMockHandlers } = configuration;

    // This is the outer state of the page.
    let pageSettings: ThisPageSettings | undefined;

    const createLocaleContext = (reactElement: ReactElement) => {
        const { lcid = 'en' } = pageSettings || {};
        return createElement(LocaleProvider, { value: lcid }, reactElement);
    };

    const createThemeContext = (reactElement: ReactElement) => {
        const { themeName = 'light' } = pageSettings || {};
        const theme = themes[themeName];
        return createElement(ThemeProvider, { theme }, reactElement);
    };

    const getDefaultUser = () => {
        const { initialSettings } = (window.WT?.Page ?? {}) as ThisPage;
        const { pageContext, lcid } = pageSettings || initialSettings || {};
        if (pageContext && lcid) {
            return new User({
                ...guestUser,
                lcid,
                domainLcid: lcid,
                countryCode: pageContext.countryCode,
                countryName: pageContext.countryName,
                channel: { user: pageContext.channel?.user ?? '' },
            });
        }
        return new User(guestUser);
    };

    const ensureUser = (currentUser: CurrentUser | User | null): User => {
        let resultUser = currentUser ? new User(currentUser) : getDefaultUser();
        if (pageSettings) {
            resultUser = new User({ ...resultUser, channel: { user: pageSettings.pageContext.channel?.user ?? '' } });
        }
        return resultUser;
    };

    const setUserDependServices = (currentUser: CurrentUser | User | null): User => {
        if (currentUser?.id) {
            linkUser(currentUser?.id as number);
        }
        if (process.env.USE_SENTRY) {
            sentryClient.setContext('currentUser', currentUser);
        }
        return ensureUser(currentUser);
    };

    const initializeAndIdentifyUser = (user: User) => {
        const posthogApiKey = isProd()
            ? process.env.REACT_APP_POSTHOG_API_KEY_PROD
            : process.env.REACT_APP_POSTHOG_API_KEY;
        if ((isProd() || (localStorage.getItem('posthog-init') ?? 'true') === 'true') && posthogApiKey) {
            initializePostHog(posthogApiKey);
            if (user.isAuth && typeof user.kratosId === 'string') {
                posthogIdentifyUser(user.kratosId);
            }
        } else {
            console.log('PostHog disabled');
        }
    };

    const initServices = ({ pageContext, currentUser, lcid, disabledGA = false }: ThisPageSettings) => {
        if (process.env.USE_SENTRY) {
            // Initialize Sentry
            sentryClient.init();
            sentryClient.setContext('lcid', { lcid });
        }
        // Initialize URLs
        UrlProcessor.setUrlMap(urlMap);

        // Initialize dictionary
        Dic.setLcid(lcid);

        // Initialize notifications
        const notifyMountPoint = document.createElement('div');
        notifyMountPoint.classList.add('notify-service');
        document.body.appendChild(notifyMountPoint);
        const notifyProcess = new NotifyProcess(notifyMountPoint, 10000, (reactElement: ReactElement) =>
            createLocaleContext(createThemeContext(reactElement)),
        );
        notify.setCustomHandler(notifyProcess.getNotifyMethods());

        const isInitPerformanceAndAnalyticsCookiesSettings = cookiePrivacySettings.isInitSetting(
            'performanceAndAnalyticsCookies',
        );
        if (isInitPerformanceAndAnalyticsCookiesSettings) {
            const {
                googleAnalytics: { gtagId },
                domainLcid,
            } = pageContext;

            initGoogleAnalytics(gtagId);
            initGoogleTagManagerScript();

            if (domainLcid === 'en') {
                initializeAndIdentifyUser(currentUser);
            }

            params?.googleTagManagerAdditional();
        }

        if (!disabledGA) {
            initTxGa(lcid);

            provideTxGA().then((txGlobalAuth) => {
                if (currentUser.isAuth) {
                    const taskAgreements = createTask({
                        name: 'GAAgreements',
                        onStart: () => {
                            txGlobalAuth.requireAgreements().finally(() => taskAgreements.finish());
                        },
                    });
                    const taskUserIdentifiers = createTask({
                        name: 'GAUserIdentifiers',
                        onStart: () => {
                            txGlobalAuth
                                .requireUserIdentifiers({ requirements: { email: true } })
                                .finally(() => taskUserIdentifiers.finish());
                        },
                    });
                    taskQueue.add(taskUserIdentifiers);
                    taskQueue.add(taskAgreements);
                    taskQueue.runQueue();
                }
            });
        }

        wtUserMetricsLoadOrUpdate();
        processBuidMetrics();
        setUserDependServices(currentUser);
    };

    const initMsw = async () => {
        if (!process.env.IS_LOCAL || process.env.BUILD_ENVIRONMENT !== 'dev') return Promise.resolve();
        let handlers: MockHandler[] | undefined = [];
        if (process.env.MOCK_SERVICE_WORKER_ENABLED) {
            handlers = ((await getMockHandlers?.()) ?? {}).handlers;
        }
        const { rest } = await mswLoader();
        return startMockServiceWorkerIfNeeded([
            ...(handlers || []),
            rest.get('https://www.google-analytics.com/:some', (req, res, ctx) => res(ctx.status(200), ctx.text('1x'))),
        ]);
    };

    let root: Root | undefined;

    const render = () => {
        if (!pageSettings) return;

        const { pageContext } = pageSettings;
        const limexUser = setUserDependServices(pageSettings.currentUser);
        let popupRootSetter: (contextSlice: Partial<{ popupRoot: HTMLDivElement | null }>) => void = () => undefined;
        let popoverRootSetter: (contextSlice: Partial<{ popoverRoot: HTMLDivElement | null }>) => void = () =>
            undefined;
        const popupRootRef = createRef<HTMLDivElement>();
        const popoverRootRef = createRef<HTMLDivElement>();

        // провешиваем начальное значение контекста, чтобы попапы, которые рендерятся сразу после загрузки
        // знали куда им рендерится. значение именно начальное, так как после срабатываяния сеттера, оно становится неважным.
        // влияет, только на либы с фиксированным маунт-поинтом (например табличный ВЛ)
        const popupRootInitialContextValue = document.createElement('div');
        document.body.append(popupRootInitialContextValue);

        const rootElement = createElement(
            ErrorBoundary,
            {},
            createElement(
                LocaleProvider,
                { value: pageSettings.lcid },
                createCurrentUserContext(
                    createThemeContext(
                        createElement(
                            PageContextProvider,
                            {
                                initialValue: pageContext,
                            },
                            createElement(
                                PopupRootContextProvider,
                                {
                                    initialValue: { popupRoot: popupRootInitialContextValue },
                                    setContextSliceSetter: (contextSliceSetter) => {
                                        popupRootSetter = contextSliceSetter;
                                    },
                                },
                                createElement(
                                    PopoverRootContextProvider,
                                    {
                                        initialValue: { popoverRoot: document.createElement('div') },
                                        setContextSliceSetter: (contextSliceSetter) => {
                                            popoverRootSetter = contextSliceSetter;
                                        },
                                    },
                                    createElement(
                                        Fragment,
                                        {},
                                        createElement('div', {
                                            id: 'popupRoot',
                                            ref: popupRootRef,
                                        }),
                                        createElement('div', {
                                            id: 'popoverRoot',
                                            ref: popoverRootRef,
                                        }),
                                        renderRootNode(pageSettings),
                                        createElement(CookiePrivacySettingsPopup),
                                    ),
                                ),
                            ),
                        ),
                    ),
                    limexUser,
                ),
            ),
        );

        const mountPoint = getMountPoint();
        if (mountPoint) {
            if (!root) {
                root = createRoot(mountPoint);
            }
            root.render(rootElement);
            popupRootSetter({ popupRoot: popupRootRef.current });
            popoverRootSetter({ popoverRoot: popoverRootRef.current });
        }
    };

    let alreadyInitialized = false;
    const logger = WtLogger.register({ name: pageId });

    let onReadyCallback: (() => void) | undefined;

    // if (module.hot) {
    //     module.hot.accept('services/theme', () => {
    //         render();
    //     });
    // }

    return {
        logger,
        async saveAndRenderIfNeeded(
            initialSettingsFromApi?: InitialPageSettings<ServerData, InitialSettingsExtension>,
        ) {
            const { initialSettings: initialSettingsFromPage, onReady } = (window.WT?.Page ?? {}) as ThisPage;
            const initialSettings = initialSettingsFromApi ?? initialSettingsFromPage;
            if (!window.WT) window.WT = {};
            if (!window.WT.Page) window.WT.Page = {};

            // If there's no saveAndRenderIfNeeded defined on the window.WT.Page,
            // it means that the saveAndRenderIfNeeded() was first called from the js bundle side.
            // We need to copy it to the window.WT.Page, so the HTML side knows, that the js bundle has loaded.
            if (!window.WT.Page.saveAndRenderIfNeeded) {
                window.WT.Page.saveAndRenderIfNeeded = this.saveAndRenderIfNeeded.bind(this);
            }

            // If initialSettings is defined here, it means that either:
            // - the initial data from the server came before the js bundle;
            // - or, it's a second call from the html side after the data is ready.
            if (initialSettings) {
                if (alreadyInitialized) {
                    throw new Error(
                        'Attempted to initialize WT.Page twice.WT.Page must be initialized only once.Check your page initialization setup.',
                    );
                }

                // Save onReady callback possibly defined on the HTML side
                onReadyCallback = onReady;

                // Copy initialSettings defined on the HTML side, and reassign
                // window.WT.Page with the newly created instance of page object
                Object.assign(this, { initialSettings });
                window.WT.Page = this;

                // Initialize pageSettings
                pageSettings = {
                    ...initialSettings,
                    currentUser: ensureUser(initialSettings.currentUser),
                };

                initServices(pageSettings);

                await initMsw();

                render();
                alreadyInitialized = true;

                onReadyCallback?.();
            }
        },
        setLocale(lcid) {
            if (!pageSettings) return;
            if (!lcid) return;

            const { currentUser } = pageSettings;
            const newCurrentUser = new User({ ...(currentUser || guestUser), lcid });

            sentryClient.setContext('lcid', { lcid });
            setUserDependServices(newCurrentUser);
            pageSettings = produce(pageSettings, (draft) => {
                draft.currentUser = currentUser;
                draft.lcid = lcid;
            });

            render();
        },

        setCurrentUser(currentUser) {
            if (!pageSettings) return;

            setUserDependServices(currentUser);
            pageSettings = produce(pageSettings, (draft) => {
                draft.currentUser = ensureUser(currentUser);
            });

            if (currentUser) {
                this.setLocale(currentUser.lcid);
            }
        },

        getCurrentUser() {
            return Promise.resolve(pageSettings?.currentUser || ensureUser(null));
        },

        getTokens() {
            return Promise.resolve(pageSettings?.pageContext.tokens);
        },

        setTheme(themeName: ThemeName) {
            if (!pageSettings) return;

            pageSettings = {
                ...pageSettings,
                themeName,
            };

            render();
        },
        set onReady(callback: () => void) {
            if (alreadyInitialized) {
                callback();
                return;
            }

            onReadyCallback = callback;
        },
        createThemeContext,
        createLocaleContext,
    };
}

let serviceWorker: MockServiceWorker | undefined;

async function startMockServiceWorkerIfNeeded(handlers: MockHandler[] = []) {
    if (!serviceWorker) {
        const { startMockServiceWorker } = await mswLoader();
        serviceWorker = await startMockServiceWorker(handlers);
    }

    return Promise.resolve();
}

export type { Page, InitPageConfiguration, InitialPageSettings };
export { getCommonInitialPageData } from 'services/init-page/getCommonInitialPageData';
