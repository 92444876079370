import { css, FlattenInterpolation, keyframes, ThemedStyledProps } from 'styled-components';
import { Styles } from 'polished/lib/types/style';

import { Theme } from 'services/theme';
import { mapObjectToObject } from 'services/utils/map-object';

// MARK: - Responsive Layout Utilities

export type CSSLiteral<Props = unknown> = FlattenInterpolation<ThemedStyledProps<Props, Theme>>;

export type ResponsiveBreakpoint = keyof typeof mediaQuery;

export type ResponsiveMap<T> = Partial<Record<ResponsiveBreakpoint, T>> & {
    default: T;
};
export type MediaQueriesMap = Record<ResponsiveBreakpoint, string>;

/**
 * Media queries fragments for every breakpoint
 * @example
 * const style = `
 *   width: 100px;
 *
 *   ${mediaQuery.lt960} {
 *     width: 70px;
 *   }
 *
 *   ${mediaQuery.lt768} {
 *     width: 50px;
 *   }
 * `;
 */
export const mediaQuery = {
    // НЕ менять порядок ключей, порядок важен для reduceBreakpoints
    gteq1400: '@media (min-width: 1400px)',
    lt1400: '@media (max-width: 1399px)',
    lt1200: '@media (max-width: 1199px)',
    lt960: '@media (max-width: 959px)',
    lt768: '@media (max-width: 767px)',
    lt480: '@media (max-width: 479px)',
} as const;

export const scrollWidth = 8;
export const scrollHeight = 8;

/**
 * For every breakpoint in the `responsiveMap`, it will wrap the result of a call to the `styleCallback`
 * into the corresponding media query, concatenating the resulting CSS.
 *
 * @example
 * const responsiveSize = {
 *   lt960: 'small',
 *   lt1200: 'big',
 *   default: 'large'
 * };
 *
 * const cssWithMediaQueries = reduceBreakpoints(responsiveSize, (size) => {
 *   switch(size) {
 *      case 'small':
 *         return css`width: 5px;`;
 *      case 'big':
 *         return css`width: 10px;`;
 *      case 'large':
 *         return css`width: 15px;`;
 *   }
 * });
 */
export function reduceBreakpoints<
    ResponsiveValue extends string | number | { [key in keyof CSSStyleDeclaration]?: string | number },
>(
    sizeOrSizeMap: ResponsiveValue | ResponsiveMap<ResponsiveValue>,
    // string это не любая строка, а css с именем свойства и значением - display: block;
    styleCallback: (value: ResponsiveValue) => CSSLiteral | Styles | string,
) {
    if (typeof sizeOrSizeMap !== 'object') {
        // Handle single size as argument
        return styleCallback(sizeOrSizeMap);
    }

    // Handle size map as argument
    const sizeMap = sizeOrSizeMap as ResponsiveMap<ResponsiveValue>;
    const entries = Object.entries(sizeMap);
    if (!entries.length) {
        throw new Error('An empty responsive size map was provided. You must at least provide the "default" property.');
    }

    const mediaBreakpoints = Object.keys(mediaQuery) as ResponsiveBreakpoint[];
    const breakpoints = ['default', ...mediaBreakpoints] as const;

    // For every breakpoint in the sizeMap, execute stylePerBreakpoint,
    // and concatenate the results
    return breakpoints.reduce((result, breakpoint) => {
        const value = sizeMap[breakpoint];
        if (!value) {
            return result;
        }

        const stylePerBreakpoint = styleCallback(value) ?? '';
        const mediaStyle =
            breakpoint === 'default'
                ? stylePerBreakpoint
                : css`
                      ${mediaQuery[breakpoint]} {
                          ${stylePerBreakpoint}
                      }
                  `;

        return css`
            ${result}\n${mediaStyle}
        `;
    }, css`` as CSSLiteral);
}

/**
 * For every breakpoint and its value in the `responsiveMap`,
 * it will wrap the styles at `stylesMap[value]` with the corresponding media query, concatenating the resulting CSS.
 *
 * @example
 * const stylesMap = {
 *   small: css`width: 5px;`,
 *   big: css`width: 10px;`,
 *   large: css`width: 15px;`
 * }
 *
 * const responsiveSize = {
 *   lt960: 'small',
 *   lt1200: 'big',
 *   default: 'large'
 * };
 *
 * const cssWithMediaQueries = matchBreakpoints(responsiveSize, stylesMap);
 */
export function matchBreakpoints<T extends string | number>(
    sizeOrSizeMap: T | ResponsiveMap<T>,
    stylesMap: Record<T, CSSLiteral | string>,
) {
    return reduceBreakpoints(sizeOrSizeMap, (value) => stylesMap[value]);
}

export const boxShadowLikeBorder = (width: string, color: string) => `inset 0 0 0 ${width} ${color}`;

// MARK: - Animation Utilities

export const animations = {
    pulse: keyframes`
        0% { opacity: 0.1; }
        50% { opacity: 1; }
        100% { opacity: 0.1; }
    `,
    pulsar: keyframes`
        0% { transform:scale3d(1, 1, 1); }
        50% { transform: scale3d(0.95, 0.95, 1); }
        100% { transform: scale3d(1, 1, 1); }
    `,
    spin: keyframes`
        100% { transform: rotate(360deg); }
    `,
} as const;

export const skeletonAnimation = css`
    animation: ${animations.pulse} 1s infinite ease-in-out;
`;

// MARK: - Style Resets

/** CSS for resetting the default browser styles for the <button /> element */
export const buttonResetStyle = css`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
`;

/** CSS for resetting the default browser styles for the <a /> element */
export const anchorResetStyle = css`
    color: inherit;
    text-decoration: inherit;
`;

/** CSS for resetting the default browser styles for the <li />/<ul /> elements */
export const listResetStyle = css`
    list-style: none;
    padding: 0;
    margin: 0;
`;

/** CSS for resetting the default browser styles for the <p /> element */
export const paragraphResetStyle = css`
    padding: 0;
    margin: 0;
`;

/** CSS for resetting the default browser styles for the <h1,2... /> elements */
export const headerResetStyle = css`
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-size: inherit;
`;

export const hiddenScroll = css`
    overflow: auto;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    & ::-webkit-scrollbar {
        display: none;
        width: 0 !important;
        -webkit-appearance: none;
    }
`;

export const labelPrimary = ({ theme }: { theme: Theme }) => theme.label.primary;
export const labelSecondary = ({ theme }: { theme: Theme }) => theme.label.secondary;
export const loadingColor = ({ theme }: { theme: Theme }) => theme.fillIn.secondary.overlay24;

interface HandlerSizeCommonArgs<SizeValueForConverting extends string, ReturnSizeValue extends string> {
    size: SizeValueForConverting | ResponsiveMap<SizeValueForConverting>;
    handler: (size: SizeValueForConverting) => ReturnSizeValue;
    defaultValue: SizeValueForConverting;
}
/**
 * convertResponsiveSize
 *
 * Конвертирует значения размеров из объекта `size` с использованием функции-обработчика `handler`.
 * Возвращает либо одно значение, преобразованное с помощью `handler`, либо объект, где каждому breakpoint соответствует значение, преобразованное с помощью `handler`.
 *
 * @param args - объект с параметрами.
 * @param args.size - значение размера (sizeValue) или объект с размерами (ResponsiveMap<sizeValue>) для различных breakpoints.
 * @param args.handler - функция для преобразования значений размера в объект или другой размер/строку/число.
 * @param args.defaultValue - значение по умолчанию, используемое, если для breakpoint нет значения в объекте `size`.
 *
 * @returns {ReturnSizeValue | ResponsiveMap<ReturnSizeValue> | undefined} - конвертированное значение размера или объект с размерами для различных breakpoints.
 *
 * @example
 * const convertTabButtonSizeToCounterSize = (size: TabButtonItemSizeValue): CounterShortNameSizeValue => {
 *   switch (size) {
 *     case 'XS':
 *     case 'S':
 *       return 'S';
 *     case 'M':
 *       return 'M';
 *     case 'L':
 *       return 'L';
 *     case 'XL':
 *     default:
 *       return 'XL';
 *   }
 * };
 *
 * const sizeCounter = convertResponsiveSize({
 *   size: 'M',
 *   handler: convertTabButtonSizeToCounterSize,
 *   defaultValue: 'M'
 * });
 * // Вернёт 'M'
 *
 * const responsiveSizeCounter = convertResponsiveSize({
 *   size: { lt960: 'S', default: 'M' },
 *   handler: convertTabButtonSizeToCounterSize,
 *   defaultValue: 'M'
 * });
 * // Вернёт объект, где каждому breakpoint соответствует значение: { lt960: 'S', default: 'M' }
 */
export const convertResponsiveSize = <SizeValueForConverting extends string, ReturnSizeValue extends string>(
    args: HandlerSizeCommonArgs<SizeValueForConverting, ReturnSizeValue>,
): ReturnSizeValue | ResponsiveMap<ReturnSizeValue> | undefined => {
    const { size, handler, defaultValue } = args;

    if (typeof size === 'string') {
        return handler(size);
    }

    if (typeof size === 'object' && size.default) {
        return mapObjectToObject(size, (breakpoint) => {
            const sizeValue = size[breakpoint] || defaultValue;
            return handler(sizeValue);
        });
    }

    return undefined;
};
