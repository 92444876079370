import { compile, CompileOptions, pathToRegexp } from 'path-to-regexp';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

import { reactRouteToPathToRegexpRoute } from './utils';

/**
Функция getBuildFunction возвращает функцию, которая строит URL на основе
переданного пути, параметров и опций компиляции.
*/
export function getBuildFunction() {
    return (
        path: string,
        params: Record<string, string | number | boolean | undefined | null> = {},
        options?: CompileOptions,
    ): string => {
        /**
        Преобразует роут react-router-а в формат, совместимый с path-to-regexp.
        */
        const ptrPath = reactRouteToPathToRegexpRoute(path);

        /**
        Извлекает ключи (имена параметров) из пути.
        */
        const { keys } = pathToRegexp(ptrPath);
        const routeParams = keys.map(({ name }) => name);

        /**
        Преобразует значения параметров в строки, если они являются строками, числами или булевыми значениями.
        */
        const convertedParams = Object.entries(params).reduce((acc, [key, value]) => {
            if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
                acc[key] = `${value}`;
            }
            return acc;
        }, {} as Record<string, string>);

        /**
        Извлекает параметры, которые используются в маршруте, и добавляет lcid
        */
        const routeParamsValues = pick({ ...convertedParams }, ...routeParams);

        /**
        Извлекает параметры, которые не используются в маршруте (например, для query-строки).
        */
        const searchParamsValues = omit(convertedParams, 'hash', ...routeParams);

        /**
        Извлекает хеш, если он был передан.
        */
        const { hash } = convertedParams;

        /**
        Компилирует путь с подставленными значениями параметров.
        */
        const pathname = compile(ptrPath, options)(routeParamsValues);

        /**
        Формирует строку query-параметров из оставшихся значений.
        */
        const searchString = new URLSearchParams(searchParamsValues).toString();

        /**
        Возвращает итоговый URL, включающий путь, query-строку (если есть), и хеш (если есть).
        */
        return pathname + (searchString ? `?${searchString}` : '') + (hash ? `#${hash}` : '');
    };
}
