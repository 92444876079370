import styled from 'styled-components';
import { ifNotProp } from 'styled-tools';

import { mediaQuery, reduceBreakpoints } from 'app/styled';
import { tokens } from 'services/tokens';

import { Z_INDEX_DIALOG, Z_INDEX_OVERLAY } from '../constants';

import { Props as PopupWindow3 } from './types';

export const Overlay = styled.div<{ zIndex?: number }>`
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: ${({ zIndex }) => zIndex ?? Z_INDEX_OVERLAY};
    overflow: auto;
    background-color: ${({ theme }) => theme.bg.dialogOverlay};
`;

export const PopupWindow3Wrapper = styled.div<{
    isVisible?: boolean;
}>`
    ${ifNotProp('isVisible', 'display: none;')}
`;

const DIALOG_INDENT = 64;
const WINDOW_TOP_INDENT_MOBILE_FULLSCREEN = 24;
export const DialogWrapper = styled.div<{
    isMobileFullscreen: boolean;
    isFullscreen: boolean;
    isBottomLeftPosition: boolean;
    zIndex?: number;
}>`
    padding: ${({ isBottomLeftPosition, isFullscreen }) => {
        if (isFullscreen) {
            return '0';
        }

        if (isBottomLeftPosition) {
            return '0 0 40px 40px';
        }

        return `${DIALOG_INDENT}px`;
    }};
    z-index: ${({ zIndex }) => (zIndex === undefined ? Z_INDEX_DIALOG : zIndex + 1)};
    position: fixed;
    display: flex;
    justify-content: ${({ isBottomLeftPosition }) => (isBottomLeftPosition ? 'start' : 'space-around')};
    align-items: ${({ isBottomLeftPosition }) => (isBottomLeftPosition ? 'end' : 'center')};
    width: ${({ isFullscreen }) => (isFullscreen ? '100%' : `calc(100% - ${DIALOG_INDENT * 2}px)`)};
    height: ${({ isFullscreen }) => (isFullscreen ? '100%' : `calc(100% - ${DIALOG_INDENT * 2}px)`)};
    ${({ isBottomLeftPosition }) => (isBottomLeftPosition ? 'bottom' : 'top')}: 0;
    left: 0;

    ${mediaQuery.lt768} {
        top: unset;
        bottom: 0;
        padding: 0;
        width: 100%;

         ${({ isFullscreen, isMobileFullscreen }) => {
             if (isFullscreen || isMobileFullscreen) {
                 return 'height: 100%;';
             }

             return `
                 height: 100%;
                 max-height: calc(var(--100vh) - ${WINDOW_TOP_INDENT_MOBILE_FULLSCREEN}px);
                 `;
         }}
`;

const dialogWidthSizeMap = ({ $width }: { $width: PopupWindow3['width'] }) => {
    if (typeof $width === 'number') {
        return `width: ${$width}px;`;
    }

    if ($width) {
        return reduceBreakpoints($width, (_width) => `width: ${_width}px;`);
    }

    return `
        max-width: 640px;
        min-width: 384px;
     `;
};

export const dialogBgColor = tokens.colors.bg.base.default.default;
export const dialogBoxShadow = `0 24px 56px 0 ${tokens.colors.shadow['5x']}, 0 10px 20px 0 ${tokens.colors.shadow['5x']},
        0 2px 4px 0 ${tokens.colors.shadow['2x']}`;

export const Dialog = styled.div<
    Pick<PopupWindow3, 'isMobileFullscreen' | 'isFullscreen'> & {
        $width: PopupWindow3['width'];
    }
>`
    display: flex;
    flex-direction: column;
    background-color: ${dialogBgColor};
    z-index: 10001;
    position: relative;
    border-radius: ${({ isFullscreen }) => (isFullscreen ? '0' : '12px')};
    height: ${({ isFullscreen }) => (isFullscreen ? '100%' : 'auto')};
    ${({ isFullscreen }) => (isFullscreen ? 'width: 100%;' : dialogWidthSizeMap)};
    max-height: 100%;
    min-height: 240px;
    overflow: hidden;
    box-shadow: ${dialogBoxShadow};

    ${mediaQuery.lt768} {
        width: 100%;
        min-width: 0;
        align-self: flex-end;
        max-width: unset;

        ${({ isFullscreen, isMobileFullscreen }) => {
            if (isFullscreen || isMobileFullscreen) {
                return 'border-radius: 0; height: 100%;';
            }

            return 'border-bottom-left-radius: 0; border-bottom-right-radius: 0;';
        }};
    }
`;
