import React, { useMemo } from 'react';

import { CloseIcon } from 'components/icons2/CloseIcon';
import { ArrowLeftIcon } from 'components/icons2/ArrowLeftIcon';
import { CounterSizeValue } from 'components/indicator/counter/types';

import { DEFAULT_SIZE_WT_POPUP_3 } from '../constants';

import * as Styled from './styled';
import { HeaderWtPopup3PropsType } from './types';

export const Header = (props: HeaderWtPopup3PropsType) => {
    const {
        onClickBackButton = null,
        onClickCloseButton = null,
        isMobileFullscreen = false,
        size = DEFAULT_SIZE_WT_POPUP_3,
        title,
        counter,
        closeButtonClassName,
        closeButtonEl,
    } = props;

    const counterSize: CounterSizeValue = useMemo(() => {
        switch (size) {
            case 'tiny':
            case 'small':
                return 'medium';
            default:
                return 'large';
        }
    }, [size]);

    return (
        <Styled.HeaderWrapper size={size} isMobileFullscreen={isMobileFullscreen}>
            <Styled.Header size={size} isMobileFullscreen={isMobileFullscreen}>
                <Styled.Content>
                    {onClickBackButton && (
                        <Styled.ButtonArrowLeft
                            size="tiny"
                            kind="ghost-secondary"
                            icon={ArrowLeftIcon}
                            sizeHeader={size}
                            onClick={(e) => onClickBackButton?.(e)}
                        />
                    )}

                    {title && (
                        <Styled.TitleWrapper>
                            <Styled.Title size={size}>{title}</Styled.Title>
                            {counter && (
                                <Styled.Counter size={counterSize} sizeHeader={size}>
                                    {counter}
                                </Styled.Counter>
                            )}
                        </Styled.TitleWrapper>
                    )}
                </Styled.Content>

                {closeButtonEl ||
                    (onClickCloseButton && (
                        <Styled.CloseButtonStyled
                            popupSize={size}
                            size="tiny"
                            kind="ghost-secondary"
                            icon={CloseIcon}
                            onClick={(e) => onClickCloseButton?.(e)}
                            className={closeButtonClassName}
                        />
                    ))}
            </Styled.Header>
        </Styled.HeaderWrapper>
    );
};
