import styled, { css } from 'styled-components';

import { matchBreakpoints, mediaQuery } from 'app/styled';
import { SmartButton } from 'components/button2';

import { SizeWtPopup3Enum } from '../constants';

import {
    ActionButtonsAlignType,
    ActionButtonsDirectionType,
    FooterActionButtonsType,
    FooterWtPopup3Props,
} from './types';

const buildSizesWrapper = ({ size, isEmpty }: Required<Pick<FooterWtPopup3Props, 'size'> & { isEmpty: boolean }>) =>
    matchBreakpoints(size, {
        [SizeWtPopup3Enum.TINY]: `padding: ${isEmpty ? '0 16px 24px' : '24px 16px'};`,
        [SizeWtPopup3Enum.SMALL]: `padding: ${isEmpty ? '0 32px 24px' : '24px 32px'};`,
        [SizeWtPopup3Enum.MEDIUM]: `padding: ${isEmpty ? '0 32px 24px' : '24px 32px'};`,
        [SizeWtPopup3Enum.LARGE]: `padding: ${isEmpty ? '0 48px 24px' : '24px 48px'};`,
    });

export const FooterWrapper = styled.div<Required<Pick<FooterWtPopup3Props, 'size'>> & { isEmpty: boolean }>`
    ${buildSizesWrapper};

    ${mediaQuery.lt768} {
        ${({ isEmpty }) => buildSizesWrapper({ size: SizeWtPopup3Enum.TINY, isEmpty })};
    }
`;

export const ActionButtons = styled.div<{
    $direction: ActionButtonsDirectionType;
    align?: ActionButtonsAlignType;
    isGrow: FooterActionButtonsType['isGrow'];
}>`
    display: flex;
    flex-grow: ${({ isGrow }) => (isGrow ? '1' : 'initial')};
    ${({ $direction }) =>
        matchBreakpoints($direction, {
            horizontal: css`
                flex-direction: row;
                gap: 8px;
            `,
            vertical: css`
                flex-direction: column;
                gap: 12px;
            `,
        })}

    ${({ align }) => {
        if (!align) {
            return '';
        }
        return `justify-content: ${align === 'left' ? 'flex-start' : 'flex-end'};`;
    }}
`;

export const ActionButton = styled(SmartButton)<{
    $direction: ActionButtonsDirectionType;
    isGrow: FooterActionButtonsType['isGrow'];
}>`
    flex-grow: ${({ isGrow }) => (isGrow ? '1' : 'initial')};
    flex-basis: ${({ isGrow }) => (isGrow ? '50%' : 'auto')};
`;
