import { useCallback, useEffect, useRef, useState } from 'react';

import { cookiePrivacySettings } from 'services/cookie-privacy-settings';
import { taskQueue, createTask } from 'services/task-queue';

import { SettingsValues } from '../types/CookiePrivacySettingsPopupUI.types';
import { isShowCookiePopupUrlParam } from '../utils';

export const useGetControlDataCookiePopupWidget = () => {
    const [isShow, setIsShow] = useState(false);

    const taskRef = useRef(createTask({ name: 'CookiePrivacySettingsPopup', onStart: () => setIsShow(true) }));

    useEffect(() => {
        // начальная инициализация, установка параметров в состояния, в local storage, очистка local storage
        const LSData = cookiePrivacySettings.getSettingsFromLocalStorage();

        const addTask = () => {
            taskQueue.addTaskAndRunASAP({
                task: taskRef.current,
            });
        };

        if (LSData) {
            // если понадобится попап, то можно в url через get параметр добавить cookie-popup=1 и обновить страницу
            const isShowPopupCookieByUrlGetParam = isShowCookiePopupUrlParam();
            if (isShowPopupCookieByUrlGetParam) {
                addTask();
            }
        } else {
            addTask();
        }
    }, []);

    const setLS = useCallback((_settings: SettingsValues) => {
        const isSuccessSetSettingsToLocalStorage = cookiePrivacySettings.setSettingsToLocalStorage({
            settings: _settings,
            isReloadPageWhenSuccess: false,
        });

        if (isSuccessSetSettingsToLocalStorage) {
            taskQueue.addActionAfterDoneQueue('reload');
            taskRef.current?.finish?.();
            setIsShow(false);
        } else {
            taskRef.current?.cancel?.();
        }
    }, []);

    const setLSAndClearCookiesUncheckedSettings = useCallback(
        (_settings: SettingsValues) => {
            setLS(_settings);
            setTimeout(() => {
                cookiePrivacySettings.clearUncheckedSettingsCookies();
            }, 100);
        },
        [setLS],
    );

    const rejectAll = useCallback(() => {
        const rejectedAllSettings = cookiePrivacySettings.getRejectedAllSettings();
        setLSAndClearCookiesUncheckedSettings(rejectedAllSettings);
    }, [setLSAndClearCookiesUncheckedSettings]);

    const acceptAll = useCallback(() => {
        const acceptedAllSettings = cookiePrivacySettings.getAcceptedAllSettings();
        setLS(acceptedAllSettings);
    }, [setLS]);

    return {
        rejectAll,
        acceptAll,
        isShow,
    };
};
