import { FinamProductItemProductType } from 'types/finam-product';
import { Lcid } from 'types';
import { Dic } from 'services/dictionary';
import { sentryClient, SentryError } from 'services/sentry';

let tagId = '';

export function initGoogleAnalytics(_tagId: string) {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${_tagId}`;

    document.body.appendChild(script);

    const configScript = document.createElement('script');
    configScript.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${_tagId}');
    `;

    document.body.appendChild(configScript);
    tagId = _tagId;
}

/** Метод совместимый со старой сигнатурой */
export function sendGA(id: string, tags: (string | number)[]) {
    const [eventAction, eventLabel, eventValue] = tags;
    window?.gtag?.('event', eventAction, {
        send_to: tagId,
        event_category: id,
        event_label: eventLabel,
        value: eventValue,
    });
}

export type SendGaV4Params = {
    category: string;
    action: string;
    label: string;
    value?: string | number;
};

export const sendGaMapProductName: Record<
    FinamProductItemProductType,
    'copy_startegy' | 'signals' | 'product' | 'ru_strategy'
> = {
    UsStrategy: 'copy_startegy',
    UsSignal: 'signals',
    UsUserProduct: 'product',
    Strategy: 'ru_strategy',
};

/** Рекомендуемый метод для новых событий */
export function sendGAv4({ category, action, label, value }: SendGaV4Params) {
    window?.gtag?.('event', action, {
        send_to: tagId,
        event_category: category,
        event_label: label,
        value,
    });
}

export type SendGAv4ObjPlace =
    | 'home__subscriptions_page__tabs__us_user_product__click'
    | 'home__subscriptions_page__tabs__us_signal__click'
    | 'home__subscriptions_page__tabs__us_strategy__click'
    | 'mplc2__banner_groups_widget__call_to_action__us_signal__btn__click'
    | 'mplc2__banner_groups_widget__call_to_action__us_strategy__btn__click'
    | 'mplc2__products_widget__catalog__show_more__us_signal__click'
    | 'mplc2__products_widget__catalog__show_more__us_user_product__click'
    | 'mplc2__products_widget__catalog__show_more__us_strategy__click'
    | 'widgets__mp_share__product_pages__subscription_success_popup__on_first_render_param__us_user_product'
    | 'widgets__mp_share__product_pages__signal_strategy_page__subscription_success_popup__on_first_render_param__us_signal'
    | 'widgets__mp_share__product_pages__pages__user_product_page__purchase__click'
    | 'widgets__mp_share__product_pages__pages__signal_strategy_page__purchase__click'
    | 'widgets__mp_share__product_pages__pages__strategy__purchase__click'
    | 'widgets__mp_share__product_pages__pages__user_product_page__user_block__click'
    | 'widgets__mp_share__product_pages__pages__strategy_page__head__author_click__us_strategy'
    | 'widgets__mp_share__product_pages__pages__strategy_page__head__author_click__us_signal'
    | 'service__api_builder__api__favorites__addProductToFavorites__on_fulfilled__us_strategy'
    | 'service__api_builder__api__favorites__addProductToFavorites__on_fulfilled__us_signal'
    | 'service__api_builder__api__favorites__addProductToFavorites__on_fulfilled__us_user_product'
    | 'service__global_auth_provide__handle_registration'
    | 'service__global_auth_provide__make_login__success'
    | 'service__global_auth_provide__handle_registration__finish'
    | 'mplc2__banner_widget__click'
    | 'mplc2__screener_banner_widget__click'
    | 'mplc2__products_widget__catalog__us_strategy_card__click'
    | 'mplc2__products_widget__catalog__us_signal_card__click'
    | 'mplc2__products_widget__catalog__us_user_product_card__click'
    | 'profile__widget__header_user_widget__subscribe_button__btn_icon__is_not_subscribed__click'
    | 'home__accounts_list_box_widget__link_account__click'
    | 'home__accounts_list_box_widget__open_account__click'
    | 'home__portfolio_widget__connect_button__click'
    | 'home__product_type_tabs__item__us_strategy'
    | 'home__product_type_tabs__item__us_signal'
    | 'home__product_type_tabs__item__us_user_product'
    | 'home__sidebar_widget__favorites_click'
    | 'home__sidebar_widget__subscriptions_click'
    | 'home__sidebar_widget__creator_tools_click'
    | 'home__subscriptions_slider_widget__placeholder_subscriptions__button_discover__click'
    | 'creator__start_steps_widget__step_current_user__click'
    | 'creator__analytics_social_page__tab__likes__click'
    | 'creator__sidebar__analytics_social__click'
    | 'creator__sidebar__learning_center__click'
    | 'home__subscriptions_page__us_strategy_page__contribute__success'
    | 'widgets__mp_share__product_pages__us_strategy_page__subscription_contribute_now__success'
    | 'widgets__mp_share__product_pages__us_strategy_page__subscription_free__success'
    | 'home__overview_page__financial_assets__go_to_trading__btn__click'
    | 'feed__post__header__author_click'
    | 'feed__post__footer__like__btn_click'
    | 'feed__post__footer__send_comment__success'
    | 'feed__post__footer__share__item__click'
    | 'feed__post__post_tags__tag__btn__click'
    | 'feed__post__post_instruments__instrument__btn__click'
    | 'feed__feed_page__sidebar__feed_filter__recommended__btn__click'
    | 'feed__feed_page__sidebar__feed_filter__my_feed__btn__click'
    | 'feed__feed_page__sidebar__feed_filter__my_posts__btn__click'
    | 'feed__feed_page__sidebar__watchlist_widget__watchlist__click'
    | 'communities_page__communities_list__community_card__click'
    | 'community_page__header__subscribe_community'
    | 'search_widget__click'
    | 'creator__sidebar__limex_ai__click'
    | 'creator__limex_ai__create_portfolio'
    | 'creator__limex_ai__create_portfolio_success'
    | 'creator__limex_ai__create_info_banner'
    | 'creator__limex_ai__info_banner'
    | 'creator__limex_ai__buy_credits_button'
    | 'creator__limex_ai__buy_credits_success'
    | 'feed__post__footer__send_comment_reply__success'
    | 'challenge_tab_header_click_general'
    | 'alert_click_on_dividends_card'
    | 'alert_click_on_price_change_card'
    | 'alert_click_on_volume_change_card'
    | 'alert_click_on_performance_change_card'
    | 'alert_view_another_card'
    | 'alert_check_description_dividends_card'
    | 'alert_check_description_price_change_card'
    | 'alert_check_description_volume_change_card'
    | 'alert_check_description_performance_change_card';

let dicGAAnalyticsObj: undefined | Record<SendGAv4ObjPlace, SendGaV4Params | ''>;
// если нужно чтобы что-то отличалось относительно домена (en или ru = limex.com или limex.me), то используем domainLcid и заполняем дикворд wt_all__google_analytics__obj_data в dct
// так как здесь сам перевод не нужен, вместо перевода мы храним разные метки под разные домены
const getGADataFromDic = (args?: { domainLcid: Lcid }) => {
    const { domainLcid } = args || {};

    const dicGAAnalytics = Dic.word('wt_all__google_analytics__obj_data', undefined, domainLcid);

    if (!dicGAAnalyticsObj) {
        dicGAAnalyticsObj = JSON.parse(dicGAAnalytics);
    }

    return dicGAAnalyticsObj;
};

type SendGAv4ByPlaceArgs = { place: SendGAv4ObjPlace; domainLcid?: Lcid };

export const sendGAFromDicByPlace = (args: SendGAv4ByPlaceArgs) => {
    try {
        const { place, domainLcid = 'en' } = args;

        const dicGAAnalytics = getGADataFromDic({ domainLcid });
        const dicGAAnalyticsForPlace = dicGAAnalytics ? dicGAAnalytics?.[place] : undefined;
        if (dicGAAnalyticsForPlace === '') {
            return;
        }

        if (dicGAAnalyticsForPlace) {
            sendGAv4(dicGAAnalyticsForPlace);
        } else {
            throw new Error(`Wrong place param ${place} for dicword or it does not exist`);
        }
    } catch (error) {
        console.error('Error sending Google Analytics tag:', error);
        sentryClient.captureException(new SentryError('Error sending Google Analytics tag', 'GoogleAnalyticsError'));
    }
};
