import { AxiosError } from 'axios';

import { getCurrentUser } from 'services/wt-page-helper';
import { backendClient } from 'services/backend-client';
import { posthogLogout } from 'services/analytics-outside/posthog';

export const makeLogout = async () => {
    const currentUser = await getCurrentUser();
    if (!currentUser?.isAuth) return;

    posthogLogout();

    backendClient
        .post('/ng/wt-auth/authentication/logout/')
        .catch((error: AxiosError) => {
            if (error.response?.status !== 401) {
                throw error;
            }
        })
        .then(() => {
            const url = new URL(window.location.href);
            const search = new URLSearchParams(url.search);
            /** Удаляем параметры если залогинились через url из письма */
            if (search.has('unsubscribeKey')) {
                url.search = '';
            }
            window.location.replace(url.toString());
        });
};
