import memoize from 'lodash/memoize';

import { Lcid } from 'types';

/**
 * Возвращает объект с функциями, которые возвращают ссылки на внешние адреса. Параметры этих функций произвольные.
 */
export const getExternalLinks = memoize(() => {
    const externalLinks = {
        connectLimeAccount: () => 'https://myaccount.lime.co',
        openLimeAccount: () => 'https://lime.co/open-account/',
        cabinetLimeAccount: () => 'https://tx.lime.co/profile',
        calendlyBook: () => 'https://calendly.com/d/crm2-jyf-f5m/limex-com-free-demo',
        quantTrading: () => 'https://www.promo.limex.com/quant_trading',
        ziplimeTrading: () => 'https://www.promo.limex.com/ziplime',
        learningCenterCreatorTools: () => 'https://limex.com/tp/info/en/creator_tools/',
        tickerPage: (ticker: string) => `https://ai.limex.com/profile/BATS:${ticker}`,
        learningCenterDemoAccounts: (domainLcid: Lcid) =>
            `https://limex.${domainLcid === 'en' ? 'com' : 'me'}/tp/info/${domainLcid}/demo_account/`,
        limeRoot: () => 'https://lime.co/',
        tildaPartnersLimexMeRoot: () => 'https://partners.limex.me/ru/',
        tildaLimexRoot: ({ isRuDomain }: { isRuDomain: boolean }) =>
            isRuDomain ? process.env.BASE_URL_ME : process.env.BASE_URL_COM,
        tildaInfoLimexMePrivacyPolicy: (domainLcid: string) =>
            `https://www.info.limex.me/${domainLcid}/privacy-policy/`,
        tildaInfoLimexMeDisclaimer: (domainLcid: string) => `https://www.info.limex.me/${domainLcid}/disclaimer/`,
        tildaInfoLimexMeCookiesAndTrackers: (domainLcid: string) =>
            `https://www.info.limex.me/${domainLcid}/cookies-and-trackers/`,
        tildaInfoLimexMeTermsAndConditions: (domainLcid: string) =>
            `https://www.info.limex.me/${domainLcid}/terms-and-conditions/`,
        tildaInfoLimexMeCommunityGuidelinesRu: () => 'https://www.info.limex.me/ru/community-guidelines/', // сейчас есть только на русском
        tildaPromoLimexComMonetization: () => 'https://www.promo.limex.com/monetization',
        tildaPromoLimexComCopyTrading: () => 'https://www.promo.limex.com/copytrading',
        tildaPromoLimexComCreatorToolsFaq: () => 'https://limex.com/tp/info/en/creator_tools/#rec802776508',
        tildaLimexComLimexCopyFaq: () => 'https://limex.com/tp/info/faq_limex_copy/',
        tildaLimexComLimexSignalsFaq: () => 'https://limex.com/tp/info/faq_limex_signals/',
        tildaInfoLimexComTermsAndConditions: (domainLcid: Lcid = 'en') =>
            `https://limex.${domainLcid === 'en' ? 'com' : 'me'}/tp/info/${domainLcid}/terms-and-conditions/`,
        manualForSignalsGeneration: () =>
            'https://d3hultv93t2853.cloudfront.net/s1/manuals/v2/Manual_for_signals_generation.pdf',
        tildaMonetization: (domainLcid: string) =>
            domainLcid === 'ru'
                ? externalLinks.tildaPartnersLimexMeRoot()
                : externalLinks.tildaPromoLimexComCopyTrading(),
    };
    return externalLinks;
});
