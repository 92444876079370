import { CookiePrivacySettingsKey, LocalStorageCookiePrivacySettingsState, RequiredDisabledKeys } from './types';

export const LOCAL_STORAGE_KEY = 'limex-cookie-privacy-settings';

export const REJECTED_ALL_COOKIE_PRIVACY_SETTINGS: LocalStorageCookiePrivacySettingsState = {
    essentialCookies: true,
    performanceAndAnalyticsCookies: false,
    functionalCookies: false,
    advertisingCookies: false,
};

export const REQUIRED_AND_DISABLED_KEYS_COOKIE_PRIVACY_SETTINGS: RequiredDisabledKeys = ['essentialCookies'];

export const ACCEPTED_ALL_COOKIE_PRIVACY_SETTINGS: LocalStorageCookiePrivacySettingsState = {
    essentialCookies: true,
    performanceAndAnalyticsCookies: true,
    functionalCookies: true,
    advertisingCookies: true,
};

export const DEFAULT_COOKIE_PRIVACY_SETTINGS: LocalStorageCookiePrivacySettingsState =
    ACCEPTED_ALL_COOKIE_PRIVACY_SETTINGS;

const ESSENTIAL_COOKIES_KEYS: Array<string> = [
    'whotrades',
    'lcid',
    'gbuid',
    'ory_kratos_session',
    'ory_kratos_continuity',
    'csrf_token_*',
];

const GOOGLE_COOKIES_KEYS = ['_ga', '_gid', '_gat', '_gat_UA-*', '_ga_*', '_gcl_au'];

const PERFORMANCE_AND_ANALYTICS_COOKIES_KEYS: string[] = [
    ...GOOGLE_COOKIES_KEYS,
    '_ttp',
    't_gid',
    't_pt_gid',
    'audit',
    'receive-cookie-deprecation',
    'uid_syncd_secure',
    'suid',
    'dicbo_id',
    '_rdt_uuid',
];

const FUNCTIONAL_COOKIES_KEYS: Array<string> = [
    'tildasid',
    'tildauid',
    '_tt_enable_cookie',
    'userId',
    'device_view',
    'msToken',
    'ttwid',
    'odin_tt',
    'tt_csrf_token',
    'tt_chain_token',
];

const ADVERTISING_COOKIE_KEYS: Array<string> = [];

// ключи кук по категориям, которые должны сохраниться в браузере
// ключи с символом * проверяются на поиск в подстроку
export const COOKIES_KEYS_BY_COOKIE_PRIVACY_SETTINGS_KEYS: Record<CookiePrivacySettingsKey, Array<string>> = {
    essentialCookies: ESSENTIAL_COOKIES_KEYS,
    performanceAndAnalyticsCookies: PERFORMANCE_AND_ANALYTICS_COOKIES_KEYS,
    functionalCookies: FUNCTIONAL_COOKIES_KEYS,
    advertisingCookies: ADVERTISING_COOKIE_KEYS,
};

export const SENTRY_ERROR_NAME = 'CookiePrivacySettings';
