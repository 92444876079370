import React, { ComponentType, MouseEvent, ReactElement } from 'react';
import { createRoot, Root } from 'react-dom/client';

import { NotificationNotify, NotificationNotifyProps } from 'widgets/notify';

export type ShowNotifyProcessorProps =
    | (Partial<Omit<NotificationNotifyProps, 'text' | 'close'>> & Pick<NotificationNotifyProps, 'text'>)
    | string;

export type NotifyMethods = {
    show: (props: ShowNotifyProcessorProps) => void;
    close: () => void;
};

const DEFAULT_DELAY = 10000;

export class NotifyProcess {
    private currentTimout: ReturnType<typeof setTimeout> | null = null;

    private mountPoint: HTMLElement;

    private root: Root;

    private readonly renderWrapper;

    private readonly notifyComponent?: ComponentType<NotificationNotifyProps>;

    public delay: number;

    constructor(
        _mountPoint?: HTMLElement,
        delay?: number | null,
        renderWrapper = (reactElement: ReactElement) => reactElement,
        notifyComponent?: ComponentType<NotificationNotifyProps>,
    ) {
        this.delay = delay || DEFAULT_DELAY;
        this.renderWrapper = renderWrapper;
        this.mountPoint = _mountPoint || document.createElement('div');
        this.toggleByCss(false);
        this.root = createRoot(this.mountPoint);
        this.notifyComponent = notifyComponent;
        if (!this.mountPoint) document.body.append(this.mountPoint);
    }

    private toggleByCss(val: boolean) {
        this.mountPoint.style.display = val ? 'block' : 'none';
    }

    public show(props: ShowNotifyProcessorProps) {
        this.clearTimeout();

        const NotifyComponent = this.notifyComponent ?? NotificationNotify;

        const text = typeof props === 'string' ? props : props.text;
        const onMouseEnterProp =
            typeof props === 'string'
                ? this.clearTimeout
                : (e: MouseEvent<HTMLButtonElement>) => {
                      this.clearTimeout();
                      props?.onMouseEnter?.(e);
                  };
        const onMouseLeaveProp =
            typeof props === 'string'
                ? this.startTimeout
                : (e: MouseEvent<HTMLButtonElement>) => {
                      this.startTimeout();
                      props?.onMouseLeave?.(e);
                  };

        if (typeof props === 'string') {
            this.root.render(
                this.renderWrapper(
                    <NotifyComponent
                        text={text}
                        onMouseEnter={onMouseEnterProp}
                        onMouseLeave={onMouseLeaveProp}
                        close={this.handleClose}
                    />,
                ),
            );
        } else {
            this.root.render(
                this.renderWrapper(
                    <NotifyComponent
                        type={props.type}
                        text={props.text}
                        icon={props.icon}
                        button={props.button}
                        size={props.size}
                        className={props.className}
                        onMouseEnter={onMouseEnterProp}
                        onMouseLeave={onMouseLeaveProp}
                        close={this.handleClose}
                        closeOnClickOutside={props.closeOnClickOutside}
                    />,
                ),
            );
        }
        this.toggleByCss(true);

        this.startTimeout();
    }

    public handleClose = () => {
        this.toggleByCss(false);
    };

    public getNotifyMethods = (): NotifyMethods => ({
        close: () => this.handleClose(),
        show: (props: ShowNotifyProcessorProps) => this.show(props),
    });

    private startTimeout = () => {
        this.currentTimout = setTimeout(this.handleClose, this.delay);
    };

    private clearTimeout = () => {
        if (this.currentTimout) clearTimeout(this.currentTimout);
    };
}
